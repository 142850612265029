import {
	getParent,
	Instance,
	flow,
	destroy,
	types as t,
	cast,
	detach,
	getRoot,
} from "mobx-state-tree"

import { IGetOrdersParams, IGetPositionsParams, IGetProfileTradesParams } from "types/history"
import { EOrderOpenStatus, OrderSideEnum, OrderTypeEnum } from "types/orders"
import { TBybitWebSocketOpenOrder } from "types/bybit"
import { HistoryModelNamesEnum } from "types/models"
import { ISubAccount } from "types/subAccounts"
import { ORDER_TYPE_FLIPPED } from "constants/orders"
import { formatHistoryOrders } from "helpers/history"
import HistoryService from "services/HistoryService"
import ExchangeService from "services/ExchangeService"
import { transformDate } from "utils/dayjs"
import errorHandler from "utils/errorHandler"

import { WithdrawalDetails } from "./Withdrawal"
import { Deposit } from "./Deposit"
import { IRootStore } from "./Root"

const HistoryOrderTrade = t.model({
	amount1: t.maybeNull(t.number),
	amount2: t.maybeNull(t.number),
	date: t.number,
	fee_amount: t.maybeNull(t.number),
	fee_currency_id: t.maybeNull(t.string),
	fee_rate: t.maybeNull(t.number),
	id: t.number,
	pair_id: t.string,
	price: t.number,
	type: t.maybeNull(t.string),
})
export type IHistoryOrderTrade = Instance<typeof HistoryOrderTrade>

const HistoryOrderPair = t.model({
	symbol: t.string,
	baseCoin: t.string,
	quoteCoin: t.string,
	status: t.string,
	// lotSizeFilter: t.model({
	// 	basePrecision: t.number,
	// 	quotePrecision: t.number,
	// 	minOrderQty: t.number,
	// 	maxOrderQty: t.number,
	// 	minOrderAmt: t.number,
	// 	maxOrderAmt: t.number,
	// }),

	amount_precision: t.maybe(t.number),
	id: t.maybe(t.string),
	is_enabled: t.maybe(t.boolean),
	label: t.maybe(t.string),
	maximum_order_size: t.maybe(t.number),
	minimum_order_size: t.maybe(t.number),
	minimum_order_value: t.maybe(t.number),
	price_precision: t.maybe(t.number),
})
export type IHistoryOrderPair = Instance<typeof HistoryOrderPair>

const PositionPair = t.model({
	base_currency_code: t.string,
	quote_currency_code: t.string,
	symbol: t.string,
	price_precision: t.number,
	amount_precision: t.number,
})
export type IPositionPair = Instance<typeof PositionPair>

const Position = t.model(HistoryModelNamesEnum.POSITION, {
	base_amount: t.number,
	base_price: t.number,
	closed_at: t.maybeNull(t.string),
	direction: t.number,
	wallet_type: t.number,
	opened_at: t.string,
	pair: PositionPair,
	quote_amount: t.number,
})
export type IPosition = Instance<typeof Position>

const TradeHistory = t.model({
	amount1: t.number,
	amount2: t.number,
	date: t.number,
	fee_amount: t.string,
	fee_rate: t.number,
	id: t.number,
	order_id: t.string,
	order_qty: t.number,
	pair_id: t.string,
	price: t.number,
	side: t.string,

	type: t.maybeNull(t.string),
	wallet_type: t.maybeNull(t.number),
	fee_currency_id: t.maybeNull(t.string),
	order_status: t.maybeNull(t.string),
	tradeFeeType: t.maybeNull(t.number),
	is_leverage: t.maybeNull(t.boolean),
})
export type ITradeHistory = Instance<typeof TradeHistory>

const HistoryOrder = t
	.model(HistoryModelNamesEnum.HISTORY_ORDER, {
		amount: t.maybeNull(t.number),
		amount_filled: t.maybeNull(t.number),
		amount_original: t.maybeNull(t.number),
		amount_unfilled: t.maybeNull(t.number),
		base_amount: t.maybeNull(t.number),
		date: t.number,
		direction: t.maybeNull(t.string),
		fee_filled: t.maybeNull(t.number),
		id: t.string,
		open_at: t.maybeNull(t.number),
		order_filled_value: t.maybeNull(t.number),
		order_total_value: t.maybeNull(t.number),
		pair: t.maybeNull(HistoryOrderPair),
		price: t.maybeNull(t.number),
		price_avg: t.maybeNull(t.number),
		quote_amount: t.maybeNull(t.number),
		side: t.string, // OrderSideEnum
		side_effect: t.maybeNull(t.number),
		stop_operator: t.maybeNull(t.number),
		stop_price: t.maybeNull(t.number),
		isLeverage: t.boolean,
		symbol: t.string,
		type: t.string, // OrderTypeEnum
		updated_at: t.maybeNull(t.number),
		value_filled: t.maybeNull(t.number),
		filled_percent: t.maybeNull(t.number),
		// amount_cancelled: t.maybeNull(t.number),
		// filled_percent: t.number,
		// key: t.string,
		status: t.string,
		// done_at: t.maybeNull(t.number),
		// wallet_type: t.maybeNull(t.number),
		// trades: t.optional(t.array(HistoryOrderTrade), []),
	})
	.views(self => ({
		get totalValue() {
			return (self.amount ?? 0) * (self.price ?? 0) || self.value_filled || 0
		},
	}))
	.volatile(() => ({
		isCancelLoading: false,
		isTradesLoading: false,
	}))
	.actions(self => ({
		setIsCancelLoading(nextIsLoading: boolean) {
			self.isCancelLoading = nextIsLoading
		},
		destroy() {
			if (self) {
				// prettier-ignore
				(getParent(self, 2) as IHistory)?.removeOpenOrder(self as IOrder)
			}
		},
		loadTrades: flow(function* () {
			try {
				self.isTradesLoading = true
				const data = yield HistoryService.getOrder(self.id)
				if (data && Array.isArray(data.trades)) {
					// self.trades = cast(data.trades)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isTradesLoading = false
			}
		}),
	}))

// ts bullshit
export type IOrder = Instance<typeof HistoryOrder>
export interface IHistoryOrder extends IOrder {
	[key: string]: any
	account?: ISubAccount
}
const mapTypeToEnum = (type: string | null) => {
	switch (type) {
		case "Limit":
			return OrderTypeEnum.LIMIT
		case "Market":
			return OrderTypeEnum.MARKET
		default:
			return null
	}
}
export const History = t
	.model({
		openedOrders: t.optional(t.array(HistoryOrder), []),
		filterTypeOpenedOrders: t.optional(t.string, "LIMIT_MARKET"),
		filterTypeOrderHistory: t.optional(t.string, "LIMIT_MARKET"),
		filterTypeTradeHistory: t.optional(t.string, "LIMIT_MARKET"),
		filterOpenedOrdersCount: t.optional(t.number, 0),
		triggerOrders: t.optional(t.array(HistoryOrder), []),
		isOpenedOrdersLoading: t.optional(t.boolean, false),
		closedOrders: t.optional(t.array(HistoryOrder), []),
		tradeHistory: t.optional(t.array(TradeHistory), []),
		isClosedOrdersLoading: t.optional(t.boolean, false),
		isTradeHistoryLoading: t.optional(t.boolean, false),
		closedOrdersCount: t.optional(t.number, 0),
		filteringSymbol: t.optional(t.string, ""),
		positions: t.optional(t.array(Position), []),
		isPositionsLoading: t.optional(t.boolean, false),
		deposits: t.optional(t.array(Deposit), []),
		depositsCount: t.optional(t.number, 0),
		isDepositsLoading: t.optional(t.boolean, false),
		withdraws: t.optional(t.array(WithdrawalDetails), []),
		withdrawsCount: t.optional(t.number, 0),
		isWithdrawsLoading: t.optional(t.boolean, false),
		currentOpenTab: t.optional(t.string, "OpenOrdersTab"),
		currentOpenOrderHistoryOrderID: t.optional(t.string, ""),
	})
	.actions(self => ({
		removeOpenOrder(item: IOrder) {
			destroy(item)
		},
		setFilterOpenedOrdersCount(nextSymbol: number) {
			self.filterOpenedOrdersCount = nextSymbol
		},
		setCurrentOpenTab(nextSymbol: string) {
			self.currentOpenTab = nextSymbol
		},
		setCurrentOpenOrderHistoryOrderID(nextSymbol: string) {
			self.currentOpenOrderHistoryOrderID = nextSymbol
		},
	}))
	.views(self => ({
		get openedOrdersSellKeys() {
			return self.openedOrders.filter(o => o.side.toUpperCase() === OrderSideEnum.SELL)
			// .map(order => order.key)
		},
		get openedOrdersBuyKeys() {
			return self.openedOrders.filter(o => o.side.toUpperCase() === OrderSideEnum.BUY)
			// .map(order => order.key)
		},
		get openedOrdersPairLabels() {
			return Array.from(
				new Set([...self.openedOrders.map(order => order.symbol.replace("_", "/"))]),
			)
		},
		get openedOrdersList() {
			let filterFunction

			if (self.filterTypeOpenedOrders === "LIMIT_MARKET") {
				filterFunction = (order: any) => order.type === "Limit" || order.type === "Market"
			} else if (self.filterTypeOpenedOrders === "STOP_LIMIT") {
				filterFunction = (order: any) => order.type === "STOP_ORDER" || order.type === "STOP_LIMIT"
			} else {
				return []
			}

			const filteredOrders = self.openedOrders.filter(filterFunction)

			return filteredOrders.map(order => ({
				name: order.symbol.replace("_", "/"),
				direction: order.side,
				orderType: order.type,
				orderValue: order.totalValue,
				orderPrice: order.price,
				orderQty: order.amount,
				orderStatus: "",
				filledQty: order.amount_filled,
				isLeverage: order.isLeverage,
				unFilledQty: order.amount_unfilled,
				baseCurrencyCode: order.pair?.baseCoin,
				quoteCurrencyCode: order.pair?.quoteCoin,
				orderTime: transformDate(order.date).format("MM/DD/YYYY HH:mm:ss"),
				orderId: order.id,
				action: "Cancel",
			}))
		},
		get positionsList() {
			console.log(self.positions)

			// base_amount: t.number,
			// base_price: t.number,
			// closed_at: t.maybeNull(t.string),
			// direction: t.number,
			// wallet_type: t.number,
			// opened_at: t.string,
			// pair: PositionPair,
			// quote_amount: t.number,

			return self.positions.map(position => ({
				marginName: position.direction,
				marginDirection: position.direction,
				basePrice: position.direction,
				marginAmount: position.direction,
				indexPrice: position.direction,
				liquidationPrice: position.direction,
				pAndL: position.direction,
				pAndLPercent: position.direction,
				date: position.direction,
			}))
		},
		get openedOrdersCount() {
			return self.openedOrders.length
		},
		get closedOrdersList() {
			let filterFunction

			if (self.filterTypeOrderHistory === "LIMIT_MARKET") {
				filterFunction = (order: any) => order.type === "Limit" || order.type === "Market"
			} else if (self.filterTypeOrderHistory === "STOP_LIMIT") {
				filterFunction = (order: any) => order.type === "STOP_ORDER" || order.type === "STOP_LIMIT"
			} else {
				return []
			}

			const filteredOrders = self.closedOrders.filter(filterFunction)

			return filteredOrders.map(order => ({
				name: order.symbol.replace("_", "/"),
				direction: order.side,
				orderType: order.type,
				orderValue: order.totalValue,
				avgFilledPrice: order.price_avg,
				filledQty: order.amount_filled,
				orderPrice: order.price,
				orderQty: order.amount_original,
				baseCurrencyCode: order.pair?.baseCoin,
				quoteCurrencyCode: order.pair?.quoteCoin,
				orderStatus: order.status,
				isLeverage: order.isLeverage,
				orderTime: transformDate(order.date).format("MM/DD/YYYY HH:mm:ss"),
				orderId: order.id,
				details: "Details",
			}))
		},
		get unformattedTradeHistoryList() {
			let filterFunction

			if (self.filterTypeTradeHistory === "LIMIT_MARKET") {
				filterFunction = (trade: any) =>
					ORDER_TYPE_FLIPPED[trade.type] === "Limit" || ORDER_TYPE_FLIPPED[trade.type] === "Market"
			} else if (self.filterTypeTradeHistory === "STOP_LIMIT") {
				filterFunction = (trade: any) =>
					ORDER_TYPE_FLIPPED[trade.type] === "STOP_ORDER" || trade.type === "STOP_LIMIT"
			} else {
				return []
			}

			// return self.tradeHistory.filter(filterFunction)
			return self.tradeHistory
		},
	}))
	.views(self => ({
		get tradeHistoryList() {
			return self.unformattedTradeHistoryList.map(trade => ({
				name: trade.pair_id.replace("_", "/"),
				filledPrice: trade.price,
				filledValue: trade.amount2,
				filledQty: trade.amount1,
				id: trade.id,
				orderId: trade.order_id,
				date: transformDate(trade.date).format("MM/DD/YYYY HH:mm:ss"),
				feeCurrency: trade.fee_currency_id,
				tradingFees: trade.fee_amount,
				direction: trade.side.toLowerCase() === "buy" ? "buy" : "sell",
				orderStatus: trade.order_status,
				isLeverage: trade.is_leverage,
				orderQty: trade.order_qty,
				orderType: trade.type,
				// orderType: ORDER_TYPE_FLIPPED[trade.type],
				walletType: trade.wallet_type,
			}))
		},
	}))
	.actions(self => ({
		setFilteringSymbol(nextSymbol: string) {
			self.filteringSymbol = nextSymbol
		},
		setFilterTypeOpenedOrders(nextSymbol: string) {
			self.filterTypeOpenedOrders = nextSymbol
		},
		setFilterTypeOrderHistory(nextSymbol: string) {
			self.filterTypeOrderHistory = nextSymbol
		},
		setFilterTypeTradeHistory(nextSymbol: string) {
			self.filterTypeTradeHistory = nextSymbol
		},
	}))
	.actions(self => ({
		updateTrades(trade: ITradeHistory) {
			// let tradeObj = trade
			//
			// if (trade.tradeFeeType !== null) {
			// 	const pair: any = getParent<IRootStore>(self).terminal.pair
			// 	const feeRate = tradeObj.tradeFeeType === 1 ? pair.taker_fee_rate : pair.maker_fee_rate
			// 	const amount = tradeObj.side === 1 ? trade.amount2 : trade.amount1
			// 	const fee_amount = feeRate * amount
			//
			// 	tradeObj = {
			// 		...trade,
			// 		fee_amount: fee_amount,
			// 		fee_currency_id: tradeObj.side === 1 ? pair.quote_currency_code : pair.base_currency_code,
			// 	}
			// }
			//
			// self.tradeHistory.unshift(cast(tradeObj))
		},
		// updateOrders(o: IOrder, onOrderOpen?: () => void, onOrderClose?: (o: IHistoryOrder) => void) {
		// 	// TODO REFACTOR
		// 	const order = formatHistoryOrder(o)
		// 	if (self.filteringSymbol && o.symbol !== self.filteringSymbol) {
		// 		return
		// 	}
		//
		// 	if (isOrderDone(order)) {
		// 		const idx = self.closedOrders.findIndex(o => o.id === order.id)
		// 		if (idx !== -1) {
		// 			// self.closedOrders[idx] = cast({
		// 			// 	...self.closedOrders[idx],
		// 			// 	...order,
		// 			// 	trades: [...self.closedOrders[idx].trades],
		// 			// })
		// 		} else {
		// 			self.closedOrders.unshift(cast(order))
		// 			const openedOrder = self.openedOrders
		// 				.concat(self.triggerOrders)
		// 				.find(o => o.id === order.id)
		// 			if (openedOrder) {
		// 				openedOrder.destroy()
		// 			}
		// 			if (onOrderClose && order.status === OrderStatusEnum.FILLED) {
		// 				onOrderClose(order)
		// 			}
		// 		}
		// 	} else if (order.type === OrderTypeEnum.STOP_LIMIT) {
		// 		if (
		// 			[OrderStatusEnum.OPEN, OrderStatusEnum.PARTIAL_FILLED, OrderStatusEnum.PENDING].includes(
		// 				order.status as OrderStatusEnum,
		// 			)
		// 		) {
		// 			const triggerOrder = self.triggerOrders.find(o => o.id === order.id)
		// 			if (triggerOrder) {
		// 				triggerOrder.destroy()
		// 			}
		// 			self.openedOrders.unshift(cast(order))
		// 		} else {
		// 			const idx = self.triggerOrders.findIndex(o => o.id === order.id)
		// 			if (idx !== -1) {
		// 				self.triggerOrders[idx] = cast({
		// 					...self.triggerOrders[idx],
		// 					...order,
		// 				})
		// 			} else {
		// 				self.triggerOrders.unshift(cast(order))
		// 			}
		// 		}
		// 	} else {
		// 		const idx = self.openedOrders.findIndex(o => o.id === order.id)
		// 		if (idx !== -1) {
		// 			// self.openedOrders[idx] = cast({
		// 			// 	...self.openedOrders[idx],
		// 			// 	...order,
		// 			// 	trades: [...self.openedOrders[idx].trades],
		// 			// })
		// 		} else {
		// 			if (onOrderOpen) {
		// 				onOrderOpen()
		// 			}
		// 			self.openedOrders.unshift(cast(order))
		// 		}
		// 	}
		// },
		updateOrder(orders: TBybitWebSocketOpenOrder[]) {
			const { currentPair } = getRoot<IRootStore>(self).bybit
			const pair: IHistoryOrderPair = cast({
				symbol: currentPair.symbol,
				status: currentPair.status,
				baseCoin: currentPair.baseCoin,
				quoteCoin: currentPair.quoteCoin,
			})
			let newList: IOrder[] = [...self.openedOrders]
			orders.forEach(order => {
				if (order.category !== "spot" || order.symbol !== pair.symbol) return
				const isOpened = Object.values(EOrderOpenStatus).includes(
					order.orderStatus as EOrderOpenStatus,
				)
				if (isOpened) {
					newList.push(
						cast({
							id: order.orderId,
							direction: order.side,
							type: order.orderType,
							stop_price: +order.stopLoss, // ?
							status: order.orderStatus,
							symbol: order.symbol,
							side: order.side,
							pair,
							amount: +order.qty,
							price: +order.price,
							isLeverage: false,
							// amount_filled:
							amount_original: +order.qty,
							amount_unfilled: +order.qty,
							base_amount: +order.qty,
							quote_amount: +order.leavesValue,
							date: Math.trunc(+order.createdTime / 1000),
							open_at: Math.trunc(+order.createdTime / 1000),
							updated_at: Math.trunc(+order.updatedTime / 1000),
						}),
					)
				} else {
					newList = newList.filter(({ id }) => id !== order.orderId)
				}
			})
			newList.sort((a, b) => (b.updated_at || 0) - (a.updated_at || 0))
			self.openedOrders = cast(newList)
		},
	}))
	.actions(self => ({
		loadOpenedOrders: flow(function* (params?: IGetOrdersParams) {
			try {
				self.isOpenedOrdersLoading = true
				const data = yield HistoryService.getOpenOrders({
					...params,
					category: params?.category ?? "spot",
					symbol: params?.symbol,
				})
				if (data) {
					if (Array.isArray(data.results)) {
						const orders = formatHistoryOrders(data.results)

						detach(self.openedOrders)

						self.openedOrders = cast(orders)
					}
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isOpenedOrdersLoading = false
			}
		}),
		cancelOrder: flow(function* (id: string, name: string) {
			try {
				const loadBalances = getParent<IRootStore>(self).bybit.loadBorrowQuota
				const order = self.openedOrders.find(o => o.id === id)

				if (order) {
					const item = yield ExchangeService.cancelOrder(id, "spot", name)

					if (item.order_id) {
						loadBalances().then(() => null) // TODO: remove this when we have WS
						self.openedOrders = cast(self.openedOrders.filter(o => o.id !== item.order_id))
					}
				}
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadClosedOrders: flow(function* (params?: IGetOrdersParams) {
			try {
				self.isClosedOrdersLoading = true
				const data = yield HistoryService.getOrdersHistory({
					category: params?.category ?? "spot",
					symbol: params?.symbol,
				})

				if (data?.results && Array.isArray(data?.results)) {
					const orders = formatHistoryOrders(data.results)
					self.closedOrders = cast(orders)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isClosedOrdersLoading = false
			}
		}),

		loadTradeHistory: flow(function* (params: IGetProfileTradesParams) {
			try {
				self.isTradeHistoryLoading = true
				const data = yield HistoryService.getTrades({
					category: params?.category ?? "spot",
					symbol: params?.symbol,
				})

				if (data && Array.isArray(data.results)) {
					const trades = data.results.map((trade: ITradeHistory) => ({
						...trade,
						type: mapTypeToEnum(trade.type),
					}))
					self.tradeHistory = cast(trades)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isTradeHistoryLoading = false
			}
		}),

		loadPositions: flow(function* (params: IGetPositionsParams) {
			try {
				self.isPositionsLoading = true
				const data = yield HistoryService.getPositions(params)
				if (Array.isArray(data)) {
					self.positions = cast(data)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isPositionsLoading = false
			}
		}),
	}))
	.actions(self => ({
		loadDeposits: flow(function* (params: any) {
			try {
				self.isDepositsLoading = true
				const data = yield HistoryService.getDeposits(params)
				self.depositsCount = data.count

				if (Array.isArray(data.results)) {
					self.deposits = cast(data.results)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isDepositsLoading = false
			}
		}),
	}))
	.actions(self => ({
		loadWithdraws: flow(function* (params: any) {
			try {
				self.isWithdrawsLoading = true
				const data = yield HistoryService.getWithdraws(params)
				self.withdrawsCount = data.count

				if (Array.isArray(data.results)) {
					self.withdraws = cast(data.results)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isWithdrawsLoading = false
			}
		}),
		cancelWithdraw: flow(function* (id: number) {
			try {
				const item = yield HistoryService.cancelWithdraw(id)

				if (item.id) {
					self.withdraws = cast(
						self.withdraws.map(withdraw =>
							withdraw.id === item.id ? { ...withdraw, ...item } : withdraw,
						),
					)
				}
			} catch (err) {
				errorHandler(err)
			}
		}),
	}))

export type IHistory = Instance<typeof History>
