import React from "react"
import { FormatNumberOptions, useIntl } from "react-intl"
import Skeleton, { SkeletonProps } from "react-loading-skeleton"

import DebouncedValue from "components/UI/DebouncedValue"

import styles from "../index.module.scss"

type TProps = {
	value: number | undefined
	format: FormatNumberOptions
	unit?: string
} & SkeletonProps

const Amount: React.FC<TProps> = ({ value, format, unit, ...props }) => {
	const { formatNumber } = useIntl()
	if (!value)
		return (
			<div className={styles.trading__skeleton}>
				<Skeleton {...props} />
			</div>
		)
	return (
		<>
			<DebouncedValue value={formatNumber(value, format)} />
			{unit}
		</>
	)
}

export default Amount
