import React, { useCallback, useEffect, useMemo, useState } from "react"
import Decimal from "decimal.js"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { AccountTypeEnum } from "types/account"
import { ICreateOrderBody, TradeActionsEnum } from "types/exchange"
import { OrderTypeEnum } from "types/orders"
import { useMst } from "models/Root"
import { orderBook } from "models/Terminal/orderBook"
import { formatErrorFromServer, onSubmitValidate } from "helpers/exchange"
import { formatNumberNoRounding } from "utils/format"
import errorHandler from "utils/errorHandler"
import useAccountType from "hooks/useAccountType"
import useConfirmModal from "hooks/useConfirmModal"
import useAdjustLeverage from "hooks/useAdjustLeverage"
import useCollateralConfirmModal from "hooks/useCollateralConfirmModal"
import AccountService from "services/AccountService"
import ExchangeService from "services/ExchangeService"
import historyMessages from "messages/history"
import Error from "assets/icons/toast/Error"
import Informational from "assets/icons/toast/Informational"
import Success from "assets/icons/toast/Success"
import Alert from "assets/icons/toast/Alert"

import OrderConfirmation from "components/NewUI/Terminal/FunctionalPanel/OrderConfirmation"

const formatStringToNumber = (str: string): number => {
	if (str === "") {
		return 0
	}

	return new Decimal(
		str
			.replace(/ /g, "")
			.replace(/[^0-9.,]/g, "")
			.replace(/,/g, "."),
	).toNumber()
}

type ErrorsState =
	| {
			[key: string]: string
	  }
	| object

const useTradingForm = () => {
	const { formatNumber } = useIntl()
	const accountType = useAccountType()
	const {
		history: { loadOpenedOrders },
		terminal: { tradeForm, pairLabel, loadLastMarginLvl },
		bybit: {
			isLoaded,
			currentPair: {
				baseCoin: baseCurrencyCode,
				quoteCoin: quoteCurrencyCode,
				symbol,
				lotSizeFilter: { minOrderQty, maxOrderQty },
			},
			isMarginAvailable,
			getCurrencyCodeBySide,
			pairAmountPrecision: amountPrecision,
			pairPricePrecision: pricePrecision,
			recentTrade,
			currentPrice,
			availableBalance,
			loadBorrowQuota,
			ticker,
		},
		tickers: { filter },
		global: { isAuthenticated },
		history,
		render,
	} = useMst()
	const { openConfirmModal } = useConfirmModal()
	const { marginLvl } = useAdjustLeverage()
	const { openCollateralConfirmModal } = useCollateralConfirmModal()
	const { formatMessage } = useIntl()
	const [isOnSubmitLoading, setIsOnSubmitLoading] = useState<boolean>(false)
	const [errors, setErrors] = useState<ErrorsState>({})
	const availablePrecision = useMemo(() => {
		if (filter.tradeAction === TradeActionsEnum.BUY) {
			return pricePrecision
		}
		return amountPrecision
	}, [filter.tradeAction, pricePrecision, amountPrecision])
	const sellPrice = orderBook.getFirst(true)
	const buyPrice = orderBook.getFirst(false)

	const maxBuyingAmount = useMemo(() => {
		if (baseCurrencyCode !== "" && quoteCurrencyCode !== "" && recentTrade?.price) {
			return formatNumber(availableBalance === 0 ? 0 : availableBalance / recentTrade.price, {
				useGrouping: false,
				minimumFractionDigits: amountPrecision ?? 8,
				maximumFractionDigits: amountPrecision ?? 8,
			})
		}

		return formatNumber(0, {
			useGrouping: false,
			minimumFractionDigits: amountPrecision ?? 8,
			maximumFractionDigits: amountPrecision ?? 8,
		})
	}, [baseCurrencyCode, quoteCurrencyCode, availableBalance, recentTrade?.price, amountPrecision])

	const maxSellingAmount = useMemo(() => {
		if (baseCurrencyCode !== "" && quoteCurrencyCode !== "" && recentTrade?.price) {
			return formatNumber(availableBalance * recentTrade.price, {
				useGrouping: false,
				minimumFractionDigits: pricePrecision ?? 8,
				maximumFractionDigits: pricePrecision ?? 8,
			})
		}

		return formatNumber(0, {
			useGrouping: false,
			minimumFractionDigits: pricePrecision ?? 8,
			maximumFractionDigits: pricePrecision ?? 8,
		})
	}, [baseCurrencyCode, quoteCurrencyCode, availableBalance, pricePrecision, recentTrade?.price])

	// Determine the currency code for the selected trade action
	const currencyCodeBySide = useMemo(
		() => (getCurrencyCodeBySide ? getCurrencyCodeBySide(filter.tradeAction) : ""),
		[filter.tradeAction, getCurrencyCodeBySide, baseCurrencyCode, quoteCurrencyCode],
	)

	// Format spot/margin balance for display
	const mainBalance = useMemo(() => {
		if (!isAuthenticated) return undefined

		return formatNumberNoRounding(availableBalance, availablePrecision ?? 8)
	}, [isAuthenticated, availableBalance, availablePrecision])

	// Handle changes to the order price input
	const onChangeOrderPrice = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (value === "") {
				tradeForm.setOrderPrice(value)

				return "0"
			}

			if (!Number.isNaN(newValue)) {
				tradeForm.setOrderPrice(value)

				if (filter.tradeAction === TradeActionsEnum.SELL && mainBalance) {
					const newAmount = formatStringToNumber(tradeForm.orderQtyString)
					const newOrderValue = formatNumberNoRounding(newAmount * newValue || 0, pricePrecision)

					tradeForm.setOrderValue(newOrderValue)
					return newOrderValue
				}

				if (filter.tradeAction === TradeActionsEnum.BUY && mainBalance) {
					const newOrderValue = formatStringToNumber(tradeForm.orderValueString)
					const newAmount = formatNumberNoRounding(
						newOrderValue !== 0 && newValue !== 0 ? newOrderValue / newValue : 0,
						amountPrecision,
					)

					tradeForm.setOrderQty(newAmount)
					return newAmount
				}
			}

			return "0"
		},
		[tradeForm.orderQtyString, pricePrecision, filter.tradeAction, mainBalance, amountPrecision],
	)

	// Handle changes to the target price input
	const onChangeTargetPrice = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue)) {
				tradeForm.setTriggerPrice(value)

				return newValue
			}

			return "0"
		},
		[tradeForm.orderQtyString, pricePrecision],
	)

	// Handle blur events on the target price input
	const onBlurTargetPrice = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue)) {
				const newOrderPrice = formatNumberNoRounding(newValue, pricePrecision)

				tradeForm.setTriggerPrice(newOrderPrice)

				return newOrderPrice
			}

			return "0"
		},
		[pricePrecision],
	)

	// Handle blur events on the order price input
	const onBlurOrderPrice = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue)) {
				const newOrderPrice = formatNumberNoRounding(newValue, pricePrecision)

				tradeForm.setOrderPrice(newOrderPrice)

				return newOrderPrice
			}

			return "0"
		},
		[pricePrecision],
	)

	// Handle changes to the order value input
	const onChangeOrderValue = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (value === "") {
				tradeForm.setOrderValue("")
				tradeForm.setOrderQty("0")

				return "0"
			}

			if (!Number.isNaN(newValue)) {
				tradeForm.setOrderValue(value)

				const newPrice = formatStringToNumber(tradeForm.orderPriceString)
				const newOrderQty =
					newValue !== 0 && newPrice > 0
						? formatNumberNoRounding(newValue / newPrice, amountPrecision)
						: "0"

				tradeForm.setOrderQty(newOrderQty)

				if (filter.tradeAction === TradeActionsEnum.SELL && mainBalance) {
					const result =
						newOrderQty !== "0"
							? (formatStringToNumber(newOrderQty) / formatStringToNumber(mainBalance)) * 100
							: 0

					tradeForm.setRangePercentage(result >= 100 ? 100 : result)
				}

				if (filter.tradeAction === TradeActionsEnum.BUY && mainBalance) {
					const result = newValue !== 0 ? (newValue / formatStringToNumber(mainBalance)) * 100 : 0

					tradeForm.setRangePercentage(result >= 100 ? 100 : result)
				}

				return newOrderQty
			}

			return "0"
		},
		[tradeForm.orderPriceString, amountPrecision, filter.tradeAction, mainBalance],
	)

	// Handle blur events on the order value input
	const onBlurOrderValue = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue) && newValue !== 0) {
				const newOrderValue = formatNumberNoRounding(newValue, pricePrecision)

				tradeForm.setOrderValue(newOrderValue)

				return newOrderValue
			}

			tradeForm.setOrderValue("0")
			return "0"
		},
		[pricePrecision],
	)

	// Handle changes to the order quantity input
	const onChangeOrderQty = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (value === "") {
				tradeForm.setOrderQty("")

				tradeForm.setOrderValue("0")
				tradeForm.setRangePercentage(0)
				return "0"
			}

			if (!Number.isNaN(newValue)) {
				tradeForm.setOrderQty(value)

				const price = formatStringToNumber(tradeForm.orderPriceString)
				const newOrderValue =
					newValue * price === 0 ? "0" : formatNumberNoRounding(newValue * price, pricePrecision)

				tradeForm.setOrderValue(newOrderValue)

				if (filter.tradeAction === TradeActionsEnum.SELL && mainBalance) {
					const result = newValue !== 0 ? (newValue / formatStringToNumber(mainBalance)) * 100 : 0

					tradeForm.setRangePercentage(result >= 100 ? 100 : result)
				}

				if (filter.tradeAction === TradeActionsEnum.BUY && mainBalance) {
					const result =
						formatStringToNumber(newOrderValue) !== 0
							? (formatStringToNumber(newOrderValue) / formatStringToNumber(mainBalance)) * 100
							: 0

					tradeForm.setRangePercentage(result >= 100 ? 100 : result)
				}

				return newOrderValue
			}

			return "0"
		},
		[tradeForm.orderPriceString, pricePrecision, filter.tradeAction, mainBalance],
	)

	// Handle blur events on the order quantity input
	const onBlurOrderQty = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue) && newValue !== 0) {
				const newOrderQty = formatNumberNoRounding(newValue, amountPrecision)

				tradeForm.setOrderQty(newOrderQty)

				return newOrderQty
			}

			tradeForm.setOrderQty("0")
			return "0"
		},
		[amountPrecision],
	)

	// Handle changes to the range percentage slider
	const onChangeRangePercentage = useCallback(
		(val: number[]) => {
			const percent = val[0]

			tradeForm.setRangePercentage(percent)

			if (filter.tradeAction === TradeActionsEnum.BUY && mainBalance) {
				const mainBalanceValue = formatStringToNumber(mainBalance)
				const newOrderValue =
					(percent / 100) * mainBalanceValue !== 0
						? formatNumberNoRounding((percent / 100) * mainBalanceValue, pricePrecision)
						: "0"

				tradeForm.setOrderValue(newOrderValue)

				if (
					filter.tradeMode === OrderTypeEnum.LIMIT ||
					filter.tradeMode === OrderTypeEnum.STOP_ORDER
				) {
					const newPrice = formatStringToNumber(tradeForm.orderPriceString)
					const newOrderQty =
						formatStringToNumber(newOrderValue) / newPrice !== 0 && newPrice !== 0
							? formatNumberNoRounding(
									formatStringToNumber(newOrderValue) / newPrice,
									amountPrecision,
							  )
							: formatNumberNoRounding(0, amountPrecision)

					tradeForm.setOrderQty(newOrderQty)
				}
			}

			if (filter.tradeAction === TradeActionsEnum.SELL && mainBalance) {
				const mainBalanceValue = formatStringToNumber(mainBalance)
				const newOrderQty = formatNumberNoRounding(
					(percent / 100) * mainBalanceValue,
					amountPrecision,
				)

				tradeForm.setOrderQty(newOrderQty)

				if (
					filter.tradeMode === OrderTypeEnum.LIMIT ||
					filter.tradeMode === OrderTypeEnum.STOP_ORDER
				) {
					const price = formatStringToNumber(tradeForm.orderPriceString)
					const newOrderValue = formatNumberNoRounding(
						formatStringToNumber(newOrderQty) * price,
						pricePrecision,
					)

					tradeForm.setOrderValue(newOrderValue)
				}
			}
		},
		[
			filter.tradeAction,
			mainBalance,
			pricePrecision,
			amountPrecision,
			tradeForm.orderPriceString,
			filter.tradeMode,
		],
	)

	const recentTradeAction = useCallback(
		(percent: number) => {
			if (filter.tradeAction === TradeActionsEnum.BUY) {
				const result = recentTrade?.price
					? recentTrade.price + (recentTrade.price * percent) / 100
					: 0

				onChangeOrderPrice(formatNumberNoRounding(result, pricePrecision))
			} else {
				const result = recentTrade?.price
					? recentTrade.price - (recentTrade.price * percent) / 100
					: 0

				onChangeOrderPrice(formatNumberNoRounding(result, pricePrecision))
			}
		},
		[recentTrade, filter.tradeAction, onChangeOrderPrice, pricePrecision],
	)

	const bidTradeAction = useCallback(() => {
		if (buyPrice) onChangeOrderPrice(formatNumberNoRounding(buyPrice, pricePrecision))
	}, [buyPrice, pricePrecision, onChangeOrderPrice])

	const askTradeAction = useCallback(() => {
		if (sellPrice) onChangeOrderPrice(formatNumberNoRounding(sellPrice, pricePrecision))
	}, [sellPrice, pricePrecision, onChangeOrderPrice])

	const submitOrder = useCallback(
		async (order: any) => {
			let result = true
			if (filter.tradeMode === OrderTypeEnum.STOP_ORDER) {
				if (!tradeForm.isOpenStopOrder) {
					tradeForm.setOrder(order)
				}
			} else {
				setIsOnSubmitLoading(true)

				try {
					await ExchangeService.createOrder(order)
						.then(() => {
							toast.success(
								<div>
									<div>Success</div>
									<div className="Toastify__toast-subtext">Order created</div>
								</div>,
								{
									position: "bottom-left",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "dark",
									icon: <Success />,
								},
							)
						})
						.then(() => {
							loadOpenedOrders({
								symbol: symbol,
								category: "spot",
							}).then(() => null)
						})
						.then(() => {
							loadBorrowQuota().then(() => null)
						})
						.catch(error => {
							if (error?.data?.amount?.length) {
								toast.error(
									<div>
										<div>Error</div>
										<div className="Toastify__toast-subtext">{error?.data?.amount[0]}</div>
									</div>,
									{
										position: "bottom-left",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: "dark",
										icon: <Error />,
									},
								)
							}
							result = true
							toast.error(
								<div>
									<div>Error</div>
									<div className="Toastify__toast-subtext">{error.data.message}</div>
								</div>,
								{
									position: "bottom-left",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "dark",
									icon: <Error />,
								},
							)
						})

					onBlurOrderQty("0")
					onBlurOrderValue("0")
					onChangeRangePercentage([0])

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (err: any) {
					if (err.data?.message) {
						toast.error(
							<div>
								<div>Error</div>
								<div className="Toastify__toast-subtext">{err.data.message}</div>
							</div>,
							{
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "dark",
								icon: <Error />,
							},
						)
					}

					const catchErrors = formatErrorFromServer(err.data)

					if (
						catchErrors.serverErrors?.non_field_errors &&
						Array.isArray(catchErrors.serverErrors?.non_field_errors)
					) {
						catchErrors.serverErrors?.non_field_errors.forEach(element => {
							toast.error(
								<div>
									<div>Error</div>
									<div className="Toastify__toast-subtext">{element}</div>
								</div>,
								{
									position: "bottom-left",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "dark",
									icon: <Error />,
								},
							)
						})
					}

					if (catchErrors.serverErrors?.total && Array.isArray(catchErrors.serverErrors?.total)) {
						catchErrors.serverErrors?.total.forEach(element => {
							toast.error(
								<div>
									<div>Error</div>
									<div className="Toastify__toast-subtext">{element}</div>
								</div>,
								{
									position: "bottom-left",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "dark",
									icon: <Error />,
								},
							)
						})
					}

					result = false
				} finally {
					setIsOnSubmitLoading(false)
				}
			}
			return result
		},
		[
			filter.tradeMode,
			tradeForm.isOpenStopOrder,
			setIsOnSubmitLoading,
			onBlurOrderQty,
			onBlurOrderValue,
			onChangeRangePercentage,
		],
	)

	const onSubmit = useCallback(async () => {
		if (!isOnSubmitLoading && symbol !== "" && ticker) {
			const order: ICreateOrderBody = {
				type: filter.tradeMode.toLowerCase(),
				side: filter.tradeAction.toLowerCase(),
				amount: tradeForm.orderQtyString,
				price: tradeForm.orderPriceString,
				pair: symbol,
				category: "spot",
				is_leverage: isMarginAvailable && accountType === AccountTypeEnum.CROSS_MARGIN ? 1 : 0,
			}
			if (filter.tradeMode === OrderTypeEnum.STOP_ORDER) {
				order.stop_operator =
					ticker.lastPrice > formatStringToNumber(tradeForm.triggerPriceString) ? "2" : "1"
				order.stop_price = tradeForm.triggerPriceString
			}

			if (filter.tradeMode === OrderTypeEnum.MARKET) {
				delete order.price

				if (filter.tradeAction === TradeActionsEnum.BUY) {
					order.amount = tradeForm.orderValueString
				}
			}

			const { isValid, errors: nextErrors } = onSubmitValidate(
				order,
				+minOrderQty,
				+maxOrderQty,
				formatMessage,
				amountPrecision,
				pricePrecision,
				filter.tradeMode === OrderTypeEnum.MARKET && filter.tradeAction === TradeActionsEnum.BUY,
			)

			setErrors(nextErrors)

			if (!isValid) return

			openConfirmModal({
				title:
					filter.tradeAction === TradeActionsEnum.BUY
						? formatMessage(historyMessages.buy_order)
						: formatMessage(historyMessages.sell_order),
				width: 360,
				content: (
					<OrderConfirmation base={baseCurrencyCode} quote={quoteCurrencyCode} order={order} />
				),
				onConfirm: async () => {
					if (accountType === AccountTypeEnum.CROSS_MARGIN) {
						try {
							const collateralInfo = await AccountService.getCollateralInfo(baseCurrencyCode)
							const collateralData = collateralInfo.find(item => item.currency === baseCurrencyCode)

							if (collateralData && !collateralData.collateral_switch) {
								openCollateralConfirmModal()
								return true
							}
						} catch (error) {
							errorHandler(error)
							return false
						}
					}

					return submitOrder(order)
				},
			})
		}
	}, [
		history.currentOpenTab,
		isAuthenticated,
		isOnSubmitLoading,
		maxOrderQty,
		minOrderQty,
		symbol,
		ticker,
		filter.tradeMode,
		filter.tradeAction,
		accountType,
		formatMessage,
		amountPrecision,
		pricePrecision,
		tradeForm.orderQtyString,
		tradeForm.orderPriceString,
		tradeForm.triggerPriceString,
		tradeForm.orderValueString,
		tradeForm.isOpenStopOrder,
		submitOrder,
		isMarginAvailable,
	])

	const onSubmitAction = useCallback(async () => {
		if (!isOnSubmitLoading) await onSubmit()
	}, [onSubmit, isOnSubmitLoading])

	// Set the order price to the recent trade price when the component mounts
	useEffect(() => {
		if (isLoaded) {
			tradeForm.setOrderPrice(
				currentPrice ? formatNumberNoRounding(currentPrice, pricePrecision) : "0",
			)

			tradeForm.setTriggerPrice(
				currentPrice ? formatNumberNoRounding(currentPrice, pricePrecision) : "0",
			)
		}
	}, [pricePrecision, isLoaded])

	useEffect(() => {
		if (isAuthenticated && render.margin && isMarginAvailable) loadLastMarginLvl()
	}, [isAuthenticated, render.margin, isMarginAvailable])

	// Reset values if we change tradeAction, tradeMode
	useEffect(() => {
		if (amountPrecision) {
			tradeForm.setOrderQty("0")
		}

		if (pricePrecision) {
			tradeForm.setOrderValue("0")
		}

		tradeForm.setRangePercentage(0)
	}, [
		filter.tradeAction,
		filter.tradeMode,
		amountPrecision,
		pricePrecision,
		tradeForm,
		accountType,
	])

	useEffect(() => {
		if (tradeForm.clickedOrder && isAuthenticated) {
			const price = formatNumberNoRounding(tradeForm.clickedOrder.price, pricePrecision)

			if (typeof availableBalance !== "number") return

			const value = `${
				+tradeForm.clickedOrder.price * +tradeForm.clickedOrder.orderDepth > availableBalance
					? availableBalance
					: tradeForm.clickedOrder.price * +tradeForm.clickedOrder.orderDepth
			}`

			if (filter.tradeAction === TradeActionsEnum.SELL) {
				onChangeOrderQty(value)
				onBlurOrderQty(value)
			} else {
				onChangeOrderValue(value)
				onBlurOrderValue(value)
			}

			tradeForm.setOrderPrice(price)

			tradeForm.setClickedOrder(null)
		}
	}, [tradeForm.clickedOrder, pricePrecision, isAuthenticated])

	useEffect(() => {
		if (Object.keys(errors).length !== 0) {
			setErrors({})
		}
	}, [filter.tradeAction, filter.tradeMode])

	// upload borrow Quota - used for available balance
	useEffect(() => {
		if (isAuthenticated && symbol !== "") {
			loadBorrowQuota().then(() => null)
		}
	}, [isAuthenticated, symbol, filter.tradeAction, marginLvl])

	return {
		// order price block
		orderPriceString: tradeForm.orderPriceString,
		onChangeOrderPrice,
		onBlurOrderPrice,
		// end order price block

		// order value block
		orderValueString: tradeForm.orderValueString,
		onChangeOrderValue,
		onBlurOrderValue,
		// end order value block

		// order qty block
		orderQtyString: tradeForm.orderQtyString,
		onChangeOrderQty,
		onBlurOrderQty,
		// end order qty block

		isMarginAvailable,
		idRenderMargin: render.margin,

		rangePercentage: tradeForm.rangePercentage,
		onChangeRangePercentage,

		triggerPriceString: tradeForm.triggerPriceString,
		onBlurTargetPrice,

		tradeType: filter.tradeType,
		tradeAction: filter.tradeAction,
		setTradeAction: filter.setTradeAction,
		setTradeType: filter.setTradeType,

		setTradeMode: filter.setTradeMode,
		tradeMode: filter.tradeMode,

		currencyCodeBySide,
		mainBalance,

		maxBuyingAmount,
		maxSellingAmount,
		getCurrencyCodeBySide,

		onChangeTargetPrice,
		isOnSubmitLoading,
		onSubmit: onSubmitAction,
		baseCurrencyCode,
		quoteCurrencyCode,
		isAuthenticated,

		recentTradeAction,
		bidTradeAction,
		askTradeAction,

		// error block
		orderPriceError: "price" in errors ? errors.price : "",
		qtyError: "amount" in errors ? errors.amount : "",
		quoteQtyError: "quote_amount" in errors ? errors.quote_amount : "",
		stopPriceError: "stop_price" in errors ? errors.stop_price : "",
		// end error block

		isLoaded,
		pairLabel,
	}
}

export default useTradingForm
