import { JSX } from "react"

import { TAlphaIcon } from "types/alphaCodes"

export type TTableCellArgs<Row> = [Row, string, number]

export type TTableRender<Row> = (
	...args: TTableCellArgs<Row>
) => JSX.Element | string | number | null

export type TTableHandlerActionClick<Row> = (...args: TTableCellArgs<Row>) => void

export type TTableAction<Row> = {
	icon?: TAlphaIcon
	caption?: string
	visible?: boolean
	disabled?: boolean
	onClick?: TTableHandlerActionClick<Row>
}

export type TTableGetActions<Row> = (...args: TTableCellArgs<Row>) => Array<TTableAction<Row>>

export type TTableOnFetch<Row> = (args: { limit: number; page: number }) => Promise<{
	data: Row[]
	count?: number
}>

export enum ETableColumnAlign {
	TOP = "top",
	MIDDLE = "middle",
	BOTTOM = "bottom",
}

export type TTableColumn<Row> = {
	key: keyof Row | string
	caption: string
	width?: number | `${number}%`
	align?: ETableColumnAlign
	subline?: TTableRender<Row>
	render?: TTableRender<Row>
	actions?: TTableGetActions<Row>
}

export enum ETableFilterType {
	STRING = "string",
	SELECT = "select",
	CHECKBOX = "checkbox",
	DATE = "date",
}

type TTableFilterString = {
	type: ETableFilterType.STRING
	placeholder?: string
	icon?: TAlphaIcon
}

type TTableFilterSelect = {
	type: ETableFilterType.SELECT
	caption?: string
	items?: Array<{ value: any; label: string | number }>
}

type TTableFilterCheckbox = {
	type: ETableFilterType.CHECKBOX
	caption?: string
}

type TTableFilterDate = {
	type: ETableFilterType.DATE
	caption?: string
	minDate?: Date
	maxDate?: Date
}

export type TTableFilter<Row> = {
	key: keyof Row | string
	toRight?: boolean
	filter?: (value: any, ...args: TTableCellArgs<Row>) => boolean
} & (TTableFilterString | TTableFilterSelect | TTableFilterCheckbox | TTableFilterDate)

export type TTableFooter = false | "pagination" | "all"
