import React from "react"
import { useIntl } from "react-intl"

import financeMessages from "messages/finance"
import commonMessages from "messages/common"
import styles from "styles/components/DepositWithdrawal.module.scss"
import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"

const HelpInfo: React.FC = () => {
	const { formatMessage } = useIntl()

	return (
		<div className={styles.help_info_container}>
			<div className={styles.help_info_tip}>
				<i className="ai ai-warning" />
				<span>
					{formatMessage(financeMessages.withdrawal_info_1)}
					<InternalLink to={routes.termsOfUse} blank>
						{formatMessage(commonMessages.more)}
					</InternalLink>
				</span>
			</div>
		</div>
	)
}

export default HelpInfo
