import React, { useCallback, useState, useEffect } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import cn from "classnames"

import { IDepositMethod } from "models/Deposit"
import DepositService from "services/DepositService"
import { routes } from "constants/routing"
import { useMst } from "models/Root"
import financeMessages from "messages/finance"
import commonMessages from "messages/common"
import feesMessages from "messages/fees_trading"

import LoadingSpinner from "components/UI/LoadingSpinner"
import InternalLink from "components/InternalLink"
import ActionMethods from "components/ActionMethods"
import InfoSnack from "components/InfoSnack"
import QRCode from "components/UI/QRCode"
import ButtonMicro from "components/UI/ButtonMicro"
import useCopyClick from "hooks/useCopyClick"

import styles from "styles/components/DepositWithdrawal.module.scss"

import VerificationRequired from "./VerificationRequired"

const FORMAT_NUMBER_OPTIONS = {
	maximumFractionDigits: 8,
	useGrouping: false,
}

interface IFieldError {
	[key: string]: string
}

const RequisitesStep: React.FC = () => {
	const {
		account: { profileStatus },
		deposit: { methods, currentCurrency, currentMethod, setCurrentMethod, loadMethods, isLoading },
	} = useMst()
	const { formatNumber, formatMessage } = useIntl()
	const copyClick = useCopyClick()

	// const [amount, setAmount] = useState<string>("")
	// const [depositFee, setDepositFee] = useState<number>(0)
	const [errors, setErrors] = useState<IFieldError>({})
	const [address, setAddress] = useState<string | boolean>("")
	const [tagDeposit, setTagDeposit] = useState<string>("")

	useEffect(() => {
		loadMethods()
	}, [currentCurrency?.code])

	// useEffect(() => {
	// 	if (!currentMethod) {
	// 		setDepositFee(0)
	// 		return
	// 	}
	// 	// let fee = parseFloat(currentMethod.deposit_fee_amount)
	// 	// const feeRate = parseFloat(currentMethod.deposit_fee_rate)
	// 	let fee = parseFloat("0")
	// 	const feeRate = parseFloat("0")
	// 	if (feeRate > 0 && amount) {
	// 		fee += feeRate * parseFloat(amount)
	// 	}
	// 	setDepositFee(fee)
	// }, [currentMethod, amount])

	// useEffect(() => {
	// 	setAmount("")
	// }, [currentCurrency?.code])

	useEffect(() => {
		if (!currentMethod) return
		;(async () => {
			try {
				setAddress(true)
				const response = await DepositService.getDepositAddress({
					deposit_method: currentMethod.id,
				})
				setAddress(response.chains.address_deposit)
				setTagDeposit(response.chains.tag_deposit)
			} catch (err) {
				const newErrors: IFieldError = {}
				if (typeof errors === "object") {
					for (const [key, val] of Object.entries(errors)) {
						const value: string & string[] = val as string & string[]
						const errorText: string = Array.isArray(value) && value.length ? value[0] : value
						const matched = errorText.match(/^\[ErrorDetail\(string='(.*)', code='.*'\)\]$/)
						newErrors[key] = matched && matched.length > 1 ? matched[1] : errorText
					}
				} else {
					newErrors.non_field_errors = "Internal Server Error"
				}
				setErrors(newErrors)
				setAddress(false)
			}
		})()
	}, [currentMethod?.id])

	const handleMethodChange = useCallback((newMethod: IDepositMethod) => {
		setCurrentMethod(newMethod.id)
	}, [])

	// const handleAmountChange = useCallback(
	// 	(e: React.ChangeEvent<HTMLInputElement>): void => {
	// 		const { value } = e.target
	// 		if (currentMethod) {
	// 			setAmount(
	// 				!value || parseFloat(value) >= currentMethod.min_deposit
	// 					? value
	// 					: currentMethod.min_deposit.toString(),
	// 			)
	// 			if (errors.amount || errors.non_field_errors) {
	// 				setErrors((prevState: IFieldError) => ({
	// 					...prevState,
	// 					amount: "",
	// 					non_field_errors: "",
	// 				}))
	// 			}
	// 		}
	// 	},
	// 	[currentMethod, errors],
	// )

	// const handleInputKeyDown = useCallback((e: React.KeyboardEvent) => {
	// 	if (e.key === "Enter") {
	// 		handleSubmit()
	// 	}
	// }, [])

	// const AverageDepositTime = () =>
	// 	currentMethod?.blockchain_block_interval !== null ? (
	// 		<div className={styles.info_small_secondary}>
	// 			{formatMessage(financeMessages.average_time_to_receive_funds)}
	// 			:&nbsp;
	// 			{currentMethod &&
	// 			currentMethod.deposit_confirmations_need > 0 &&
	// 			currentMethod.blockchain_block_interval ? (
	// 				<span>
	// 					{dayjs(
	// 						dayjs(Date.now()).valueOf() +
	// 							currentMethod.blockchain_block_interval *
	// 								1000 *
	// 								currentMethod.deposit_confirmations_need,
	// 					).fromNow(true)}
	// 				</span>
	// 			) : (
	// 				<span>{formatMessage(commonMessages.instantly)}</span>
	// 			)}
	// 		</div>
	// 	) : null

	const MinDeposit = () =>
		currentMethod && currentCurrency ? (
			<div className={styles.min_deposit_container}>
				<i className="ai ai-warning" />
				{formatMessage(feesMessages.deposit_withdraw_min_deposit)}:&nbsp;
				{formatNumber(currentMethod.min_deposit, FORMAT_NUMBER_OPTIONS)}
				&nbsp;
				{currentCurrency.code}
			</div>
		) : null

	const isEnoughVerificationLevel =
		(profileStatus?.verification_level ?? 0) >= (currentMethod?.min_verification_level ?? 0)

	const handleCopyToClipboard = (): void => {
		if (!tagDeposit) return
		copyClick(tagDeposit, formatMessage(commonMessages.copied_to_clipboard, { label: "Tag" }))
	}

	return (
		<div className={styles.step_container}>
			<div className={styles.step_info}>
				<span className={styles.step_info_title}>
					2.&nbsp;{formatMessage(financeMessages.payment_details)}
				</span>
			</div>
			{isLoading ? (
				<LoadingSpinner align="top" />
			) : methods?.length > 0 && methods.some(method => method.is_deposit_enabled) ? (
				<>
					<div className={styles.form_requisites_container}>
						<ActionMethods
							title={formatMessage(financeMessages.deposit_method)}
							methods={methods}
							currentMethod={currentMethod as IDepositMethod}
							onChange={handleMethodChange}
							type="deposit"
							// withIcon={deposit_methods.every((method: IDepositMethod) => method.is_sci)}
						/>
						{/*{isEnoughVerificationLevel ? (*/}
						{/*	<div className={styles.form_input_group}>*/}
						{/*		<Input*/}
						{/*			type="number"*/}
						{/*			onChange={handleAmountChange}*/}
						{/*			value={amount}*/}
						{/*			labelValue={formatMessage(commonMessages.quantity)}*/}
						{/*			appender={<Appender>{currentCurrency?.code?.toUpperCase() ?? ""}</Appender>}*/}
						{/*			error={errors.amount || errors.non_field_errors}*/}
						{/*			// onKeyDown={handleInputKeyDown}*/}
						{/*		/>*/}
						{/*		<MinDeposit />*/}
						{/*		/!*<AverageDepositTime />*!/*/}
						{/*	</div>*/}
						{/*) : (*/}
						{/*	<>*/}
						{/*		<MinDeposit />*/}
						{/*		/!*<AverageDepositTime />*!/*/}
						{/*	</>*/}
						{/*)}*/}
						{/*{!!currentMethod && isEnoughVerificationLevel && (*/}
						{/*	<div className={styles.all_requisites_required}>*/}
						{/*		<InfoSnack color="yellow" iconCode="info_filled">*/}
						{/*			<span>{formatMessage(financeMessages.all_requisite_required)}</span>*/}
						{/*		</InfoSnack>*/}
						{/*	</div>*/}
						{/*)}*/}
						<MinDeposit />
					</div>
					{address && isEnoughVerificationLevel && (
						<div className={styles.form_action_container}>
							{address === true ? (
								<LoadingSpinner align="top" />
							) : (
								<QRCode
									value={address}
									label={formatMessage(commonMessages.address)}
									code={address}
								/>
							)}
							{tagDeposit && (
								<div className={styles.centered}>
									<div>Tag:</div>
									<div>{tagDeposit}</div>
									<ButtonMicro onClick={handleCopyToClipboard}>
										<i className="ai ai-copy_new" />
									</ButtonMicro>
								</div>
							)}
						</div>
					)}
					{!!currentMethod &&
						(isEnoughVerificationLevel ? (
							// currentMethod.is_sci ? (
							<div className={styles.form_action_container}>
								{/*<div className={styles.will_be_received}>*/}
								{/*	{formatMessage(financeMessages.receive)}:*/}
								{/*	<span>*/}
								{/*		{formatNumber(*/}
								{/*			amount ? parseFloat(amount) - depositFee : 0,*/}
								{/*			FORMAT_NUMBER_OPTIONS,*/}
								{/*		)}*/}
								{/*		&nbsp;*/}
								{/*		{currentCurrency?.code ?? ""}*/}
								{/*	</span>*/}
								{/*</div>*/}
								{/*<div className={styles.action_fee}>*/}
								{/*	{formatMessage(financeMessages.fee)}*/}
								{/*	<span>*/}
								{/*		{formatNumber(depositFee, FORMAT_NUMBER_OPTIONS)}*/}
								{/*		&nbsp;*/}
								{/*		{currentCurrency?.code}*/}
								{/*	</span>*/}
								{/*</div>*/}
								{/*<Button*/}
								{/*	className={styles.submit_button}*/}
								{/*	color="primary"*/}
								{/*	iconCode="mini_down_right"*/}
								{/*	iconAlign="left"*/}
								{/*	disabled={!(amount && currentMethod)}*/}
								{/*	// onClick={() => handleSubmit()}*/}
								{/*	label={formatMessage(coinInfoMessages.refill)}*/}
								{/*/>*/}
								<div className={styles.terms_of_use}>
									<span>
										By making Deposit you agree with the&nbsp;
										<InternalLink to={routes.termsOfUse}>Terms of Use</InternalLink>
									</span>
									{/*{config.departmentAddress ? <span>{config.departmentAddress}</span> : null}*/}
								</div>
							</div>
						) : (
							<div className={cn(styles.form_action_container, styles.with_padding)}>
								<VerificationRequired level={currentMethod?.min_verification_level ?? 0} />
							</div>
						))}
				</>
			) : (
				<InfoSnack color="red" iconCode="warning">
					<span>{formatMessage(financeMessages.deposit_unavailable)}</span>
				</InfoSnack>
			)}
		</div>
	)
}

export default observer(RequisitesStep)
