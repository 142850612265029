import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import cn from "classnames"
import dayjs from "utils/dayjs"
import financeMessages from "messages/finance"
import { IDeposit } from "models/Deposit"
import useCopyClick from "hooks/useCopyClick"
import { TableData, TableRow, TableRowAdvancedContainer } from "components/UI/Table"
import Tooltip from "components/UI/Tooltip"
import ButtonMicro from "components/UI/ButtonMicro"
import { BadgeColorEnum, OutlinedBadge } from "components/UI/Badge"
import styles from "styles/components/DepositWithdrawal.module.scss"
import { DEPOSIT_STATUS } from "./constants"

export const badgeColor = (status: DEPOSIT_STATUS): BadgeColorEnum => {
	switch (status) {
		case DEPOSIT_STATUS.UNKNOWN:
			return BadgeColorEnum.RED
		case DEPOSIT_STATUS.CONFIRMED:
			return BadgeColorEnum.GREEN
		case DEPOSIT_STATUS.PROCESSING:
			return BadgeColorEnum.ORANGE
		case DEPOSIT_STATUS.SUCCESS:
			return BadgeColorEnum.GREEN
		case DEPOSIT_STATUS.FAILED:
			return BadgeColorEnum.RED
		default:
			return BadgeColorEnum.RED
	}
}

interface Props {
	deposit: IDeposit
}

const PreviousDepositRow: React.FC<Props> = React.memo(({ deposit }) => {
	const { formatMessage, formatNumber } = useIntl()
	const copyClick = useCopyClick()
	const [txFullIsOpen, setTxFullIsOpen] = useState<boolean>(false)
	const canceled = deposit.status.id === DEPOSIT_STATUS.FAILED

	const handleCopyToClipboard = () => {
		if (deposit.txid) {
			copyClick(
				deposit.txid,
				formatMessage(financeMessages.copied_to_clipboard, {
					label: "TX",
				}),
			)
		}
	}

	const handleClickOuter = useCallback(
		(e: any) => {
			const { target } = e
			const className = `deposit_${deposit.txid}`
			if (
				!target.className.includes(className) &&
				!target.parentNode.className.includes(className)
			) {
				setTxFullIsOpen(false)
			}
		},
		[deposit],
	)

	const handleClickTx = useCallback(() => {
		if (!canceled && deposit.txid) {
			setTxFullIsOpen(true)
		}
	}, [deposit.txid, canceled])

	useEffect(() => {
		if (txFullIsOpen) {
			document.addEventListener("click", handleClickOuter)
		} else {
			document.removeEventListener("click", handleClickOuter)
		}
		return () => document.removeEventListener("click", handleClickOuter)
	}, [txFullIsOpen, handleClickOuter])

	return (
		<TableRowAdvancedContainer className={cn(canceled && styles.canceled)}>
			<TableRow>
				<TableData>
					{dayjs(+deposit.date).format("DD/MM/YYYY")}&nbsp;
					<span className={styles.table_data_grey_colored}>
						{dayjs(+deposit.date).format("HH:mm")}
					</span>
				</TableData>
				<TableData>{`${deposit.currency ?? ""}`}</TableData>
				<TableData>
					{formatNumber(+deposit.amount, {
						maximumFractionDigits: 8,
						useGrouping: false,
					})}
					{` ${deposit.currency ?? ""}`}
				</TableData>
				<TableData className={cn(styles.table_data_tx, txFullIsOpen && styles.active)}>
					{deposit.txid ? (
						<div className={styles.table_data_tx_val} onClick={handleClickTx}>
							{`${deposit.txid.slice(0, 7)}...${deposit.txid.slice(-5)}`}
							<Tooltip id="txid" place="top" backgroundColor="var(--tooltip-background)">
								{deposit.txid}
							</Tooltip>
							<ButtonMicro onClick={handleCopyToClipboard} primary>
								<i className="ai ai-copy_new" />
							</ButtonMicro>
						</div>
					) : (
						"-"
					)}
					{txFullIsOpen && (
						<div className={styles.tx_full_container}>
							{deposit.txid}
							<ButtonMicro onClick={handleCopyToClipboard} primary>
								<i className="ai ai-copy_new" />
							</ButtonMicro>
						</div>
					)}
				</TableData>
				<TableData width="100px">{/*<span>{deposit.reject_reason}</span>*/}</TableData>
				<TableData align="right" maxWidth="150px">
					{deposit.status.id === DEPOSIT_STATUS.PROCESSING ? (
						<div className={styles.pending_status}>
							<i className="ai ai-clock" />
							{deposit.status.label}
						</div>
					) : (
						<OutlinedBadge color={badgeColor(deposit.status.id)}>
							{deposit.status.label}
						</OutlinedBadge>
					)}
				</TableData>
			</TableRow>
		</TableRowAdvancedContainer>
	)
})

export default PreviousDepositRow
