import { EWithdrawalStatuses, WithdrawalStatusesEnum } from "types/withdrawal"
import { BadgeBackgroundColorEnum } from "components/UI/Badge"

export const badgeColor = (
	status: WithdrawalStatusesEnum | EWithdrawalStatuses,
): BadgeBackgroundColorEnum => {
	switch (status) {
		case WithdrawalStatusesEnum.WITHDRAW_STATUS_NEW:
		case EWithdrawalStatuses.NEW:
			return BadgeBackgroundColorEnum.BLUE

		case WithdrawalStatusesEnum.WITHDRAW_STATUS_CONFIRMED:
		case WithdrawalStatusesEnum.WITHDRAW_STATUS_VERIFIED:
		case WithdrawalStatusesEnum.WITHDRAW_STATUS_PROCESSING:
		case EWithdrawalStatuses.BLOCKCHAIN_CONFIRMED:
		case EWithdrawalStatuses.MORE_INFORMATION_REQUIRED:
		case EWithdrawalStatuses.PENDING:
			return BadgeBackgroundColorEnum.ORANGE

		case WithdrawalStatusesEnum.WITHDRAW_STATUS_DONE:
		case EWithdrawalStatuses.SUCCESS:
			return BadgeBackgroundColorEnum.GREEN

		case WithdrawalStatusesEnum.WITHDRAW_STATUS_REFUSED:
		case EWithdrawalStatuses.FAIL:
		case EWithdrawalStatuses.REJECT:
			return BadgeBackgroundColorEnum.RED

		default:
			return BadgeBackgroundColorEnum.LIGHT_GREY_DISABLED
	}
}
