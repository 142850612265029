import React from "react"
import { useIntl } from "react-intl"
import dayjs from "utils/dayjs"

import { TableData, TableRow } from "components/UI/Table"
import { IDeposit } from "models/Deposit"
import styles from "styles/components/FinanceHistory.module.scss"
import { BadgeColorEnum, OutlinedBadge } from "components/UI/Badge"
import ButtonMicro from "components/UI/ButtonMicro"
import useCopyClick from "hooks/useCopyClick"
import { DEPOSIT_STATUS } from "./constants"

interface Props {
	deposit: IDeposit
}

const DepositRow: React.FC<Props> = ({ deposit }) => {
	const { formatNumber } = useIntl()
	const copyClick = useCopyClick()
	const { txid } = deposit

	const handleCopyTxID = () => {
		if (txid) {
			copyClick(txid)
		}
	}

	const bColor = badgeColor(deposit.status.id)

	return (
		<TableRow>
			<TableData width="85px" dateMode>
				{dayjs(+deposit.date).format("DD/MM/YYYY")}&nbsp;
				<span className={styles.table_data_grey_colored}>
					{dayjs(+deposit.date).format("HH:mm:ss")}
				</span>
			</TableData>
			<TableData width="80px">{`${deposit.currency ?? ""}`}</TableData>
			<TableData>
				{formatNumber(+deposit.amount, {
					maximumFractionDigits: 8,
					useGrouping: false,
				})}
				{` ${deposit.currency ?? ""}`}
			</TableData>
			<TableData width="100px">
				{+deposit.fee_value === 0 ? "-" : <>{deposit.fee_value}&nbsp;</>}
			</TableData>
			<TableData width="300px" minWidth="300px">
				<div className={styles.table_data_tx}>
					<span>{txid || "--"}</span>
					{txid ? (
						<ButtonMicro onClick={handleCopyTxID} primary>
							<i className="ai ai-copy_new" />
						</ButtonMicro>
					) : null}
				</div>
			</TableData>

			<TableData width="200px" minWidth="200px" align="center">
				<div className={styles.table_data_status}>
					<OutlinedBadge color={bColor}>{deposit.status.label}</OutlinedBadge>
				</div>
			</TableData>
		</TableRow>
	)
}

export const badgeColor = (status: DEPOSIT_STATUS): BadgeColorEnum => {
	let color: BadgeColorEnum

	switch (status) {
		case DEPOSIT_STATUS.UNKNOWN:
			color = BadgeColorEnum.RED
			break
		case DEPOSIT_STATUS.CONFIRMED:
			color = BadgeColorEnum.GREEN
			break
		case DEPOSIT_STATUS.PROCESSING:
			color = BadgeColorEnum.ORANGE
			break
		case DEPOSIT_STATUS.SUCCESS:
			color = BadgeColorEnum.GREEN
			break
		case DEPOSIT_STATUS.FAILED:
			color = BadgeColorEnum.RED
			break
		default:
			color = BadgeColorEnum.RED
			break
	}

	return color
}

export default DepositRow
