/* eslint-disable no-case-declarations,no-void,no-unused-expressions,react/button-has-type,jsx-a11y/control-has-associated-label */
import React, {
	CSSProperties,
	FormEventHandler,
	MouseEventHandler,
	useEffect,
	useId,
	useMemo,
	useRef,
	useState,
} from "react"
import cn from "classnames"

import { getScrollbarIsOverlay } from "utils/scroll"

import DateRangePickerWrapper, { IChangeDateRange } from "components/UI/DateRangePicker"
import Input from "components/NewUI/Input"
import NewSelect, { TSelectChangeEvent } from "components/NewUI/NewSelect"

import {
	ETableColumnAlign,
	ETableFilterType,
	TTableColumn,
	TTableFilter,
	TTableFooter,
	TTableOnFetch,
} from "./types"
import { DEFAULT_LIMIT, limits } from "./constants"
import styles from "./table.module.scss"
import MiniButton from "../MiniButton"
import CheckBox from "../CheckBox"

type TProps<Row extends Record<string, any> = Record<string, any>> = {
	id?: string
	columns: TTableColumn<Row>[]
	data?: Row[]
	filters?: TTableFilter<Row>[]
	resetFilters?: boolean
	firstSticky?: boolean
	lastSticky?: boolean
	lazyLoad?: boolean
	footer?: TTableFooter
	onFetch?: TTableOnFetch<Row>
	onAllClick?: () => void
	className?: string
}

export const Table = function <Row extends Record<string, any>>({
	id = "id",
	columns,
	data,
	filters,
	resetFilters,
	firstSticky,
	lastSticky,
	lazyLoad,
	footer,
	onFetch,
	onAllClick,
	className,
}: TProps<Row>) {
	const tableId = useId()
	const rootRef = useRef<HTMLDivElement>(null)
	const headerRef = useRef<HTMLDivElement>(null)
	const formRef = useRef<HTMLFormElement>(null)
	const contentRef = useRef<HTMLDivElement>(null)
	const scrollRef = useRef<HTMLDivElement>(null)
	const footerRef = useRef<HTMLDivElement>(null)

	/* Filters */

	const [filtration, setFiltration] = useState<Record<TTableFilter<Row>["key"], any>>(
		{} as Record<TTableFilter<Row>["key"], any>,
	)

	const leftFilters = useMemo(() => filters?.filter(({ toRight }) => !toRight), [filters])
	const rightFilters = useMemo(() => filters?.filter(({ toRight }) => toRight), [filters])

	// TODO: remove this after creating normal date picker
	const dateFilterValues = useRef<Record<string, IChangeDateRange>>({})

	const handleFiltersSubmit: FormEventHandler<HTMLFormElement> = event => event.preventDefault()

	const handleFiltersChange = () =>
		setTimeout(() => {
			console.log("handleFiltersChange")

			const nodeForm = formRef.current as HTMLFormElement
			const formData = new FormData(nodeForm)
			const values = Object.fromEntries(formData.entries()) as typeof filtration
			Object.entries(dateFilterValues.current).forEach(([name, { selection }]) => {
				values[name as TTableFilter<Row>["key"]] = [selection.startDate, selection.endDate]
			})
			setFiltration(values)

			console.log(values)
		}, 0)

	const renderFilter = (filter: TTableFilter<Row>) => {
		const { key, type } = filter

		// TODO: remove this after creating normal date picker
		const [range, setRange] = useState<IChangeDateRange>()

		return (
			<div key={`table-${tableId}-filter-${key as string}`}>
				{type === ETableFilterType.STRING && (
					<Input
						className={styles.text}
						name={key as string}
						placeholder={filter.placeholder}
						prefixIcon={filter.icon && <i className={`ai ${filter.icon}`} />}
					/>
				)}
				{type === ETableFilterType.SELECT && (
					<NewSelect
						placeholder={filter.caption}
						name={key as string}
						options={filter.items?.map(({ label }, index) => ({
							label,
							value: index,
						}))}
						controlClassName={styles.select}
					/>
				)}
				{type === ETableFilterType.CHECKBOX && (
					<CheckBox name={key as string} className={styles.checkbox}>
						{filter.caption}
					</CheckBox>
				)}
				{type === ETableFilterType.DATE && (
					// TODO: replace with normal date picker
					<DateRangePickerWrapper
						ranges={range && [range.selection]}
						minDate={filter.minDate}
						maxDate={filter.maxDate}
						onChange={range => {
							setRange(range)
							dateFilterValues.current[key as string] = range
							handleFiltersChange()
						}}
						onRangeClear={() => {
							setRange(undefined)
							delete dateFilterValues.current[key as string]
							handleFiltersChange()
						}}
					/>
				)}
			</div>
		)
	}

	const filtered = useMemo<Row[]>(() => {
		if (!data?.length) return []
		if (!filters?.length) return data

		const filtersMap = new Map(filters.map(({ key, ...filter }) => [key, filter]))
		const values = Object.entries(filtration)
			.map(([key, value]) => {
				if (!filtersMap.has(key)) return [key, undefined]
				const filter = filtersMap.get(key) as TTableFilter<Row>
				switch (filter.type) {
					case ETableFilterType.STRING:
						return [key, value.toLowerCase().trim()]
					case ETableFilterType.SELECT:
						return [key, filter.items?.[value]?.value]
					case ETableFilterType.CHECKBOX:
						return [key, !!value]
					case ETableFilterType.DATE:
						return [key, [new Date(value[0]), new Date(value[1])]]
					default:
						return [key, value]
				}
			})
			.filter(([, value]) => !!value)
		if (!values.length) return data

		return data.filter((row, index) =>
			values.every(([key, value]) => {
				const filter = filtersMap.get(key) as TTableFilter<Row>
				if (filter.filter) return filter.filter(value, row, key, index)
				switch (filter.type) {
					case ETableFilterType.STRING:
						return row[key].toString().toLowerCase().includes(value)
					case ETableFilterType.SELECT:
						return row[key] === value
					case ETableFilterType.CHECKBOX:
						return !!row[key] === value
					case ETableFilterType.DATE:
						const date = new Date(row[key])
						return value[0] <= date && date <= value[1]
					default:
						return true
				}
			}),
		)
	}, [data, filters, filtration])

	/* Pagination */

	const getCount = () => (lazyLoad ? 0 : filtered?.length || 0)

	const [count, setCount] = useState<number>(getCount())
	const [limit, setLimit] = useState<number>(DEFAULT_LIMIT)
	const [page, setPage] = useState<number>(1)

	const pages = Math.ceil(count / limit)

	useEffect(() => setCount(getCount()), [filtered, lazyLoad])
	useEffect(() => void (pages < page && setPage(Math.max(pages, 1))), [pages])

	const handleSelectLimit: TSelectChangeEvent<number> = limit => setLimit(limit || limits[0])

	const handleSelectPage: MouseEventHandler<HTMLButtonElement> = ({ target }) =>
		setPage(+((target as HTMLButtonElement).dataset.page || 1))

	const handleGoPrevious = () => setPage(val => Math.max(val - 1, 1))
	const handleGoNext = () => setPage(val => Math.min(val + 1, pages))

	const rows = useMemo<Row[]>(() => {
		if (footer !== "pagination") return filtered
		return filtered.slice((page - 1) * limit, page * limit)
	}, [filtered, footer, limit, page])

	/* Scroll */

	const [isScrollOverlay, setScrollOverlay] = useState(getScrollbarIsOverlay())

	const getHeaderClassName = (isFull?: boolean) => cn(styles.header, { [styles.full]: isFull })
	const getScrollClassName = (isFull?: boolean) =>
		cn(styles.scroll, {
			[styles.overlay]: isScrollOverlay,
			[styles.full]: isFull,
		})
	const getFooterClassName = (isFull?: boolean) =>
		cn(styles.footer, {
			[styles.pagination]: footer === "pagination",
			[styles.full]: isFull,
		})

	useEffect(() => {
		const nodeRoot = rootRef.current as HTMLDivElement
		const nodeHeader = headerRef.current as HTMLDivElement
		const nodeContent = contentRef.current as HTMLDivElement
		const nodeTable = nodeContent.children[0] as HTMLTableElement
		const nodeThead = nodeTable.querySelector("thead") as HTMLTableSectionElement
		const nodeScroll = scrollRef.current as HTMLDivElement
		const nodeExpander = nodeScroll.children[0] as HTMLDivElement
		const nodeFooter = footerRef.current as HTMLDivElement | null

		const checkScroll = () => {
			setScrollOverlay(getScrollbarIsOverlay())

			nodeScroll.scrollTo({ left: nodeContent.scrollLeft })

			nodeContent.className = cn(styles.content, {
				[styles.firstStuck]: firstSticky && nodeContent.scrollLeft > 0,
				[styles.lastStuck]:
					lastSticky &&
					nodeContent.scrollWidth - nodeContent.clientWidth - nodeContent.scrollLeft > 0,
			})

			if (nodeContent.clientWidth < nodeContent.scrollWidth) {
				nodeExpander.style.setProperty(
					"width",
					`${nodeContent.scrollWidth - (nodeContent.offsetWidth - nodeScroll.offsetWidth)}px`,
				)
				nodeScroll.style.removeProperty("display")
			} else {
				nodeScroll.style.setProperty("display", "none")
				nodeExpander.style.removeProperty("width")
			}
		}

		const setScroll = () => nodeContent.scrollTo({ left: nodeScroll.scrollLeft })

		const checkHeader = () => {
			const footerHeight = (footer && nodeFooter?.offsetHeight) || 0
			nodeHeader.className = getHeaderClassName(nodeHeader.offsetTop - nodeRoot.offsetTop > 0)
			nodeScroll.className = getScrollClassName(
				nodeContent.offsetTop + nodeContent.offsetHeight - nodeScroll.offsetTop >
					nodeExpander.offsetHeight,
			)
			nodeScroll.style.setProperty("bottom", `${footerHeight}px`)
			nodeFooter &&
				(nodeFooter.className = getFooterClassName(
					nodeFooter.offsetTop +
						nodeFooter.offsetHeight -
						(nodeRoot.offsetTop + nodeRoot.offsetHeight) <
						0,
				))

			const translate = nodeHeader.offsetTop + nodeHeader.offsetHeight - nodeTable.offsetTop
			if (translate) nodeThead.style.setProperty("transform", `translateY(${translate}px)`)
			else nodeThead.style.removeProperty("transform")
		}

		const handleScroll = ({ target }: Event) => {
			if (target === nodeScroll) setScroll()
			else if (target === nodeContent) checkScroll()
			checkHeader()
		}

		checkScroll()
		checkHeader()

		window.addEventListener("scroll", handleScroll, true)
		window.addEventListener("resize", checkScroll)
		return () => {
			window.removeEventListener("scroll", handleScroll, true)
			window.removeEventListener("resize", checkScroll)
		}
	}, [columns.length, rows?.length, firstSticky, lastSticky, footer])

	/* Render */

	return (
		<div ref={rootRef} className={cn(styles.table, className)}>
			<div ref={headerRef} className={getHeaderClassName()}>
				{filters?.length && (
					<form
						ref={formRef}
						onReset={handleFiltersChange}
						onChange={handleFiltersChange}
						onSubmit={handleFiltersSubmit}
					>
						{!!leftFilters?.length && <div>{leftFilters.map(renderFilter)}</div>}
						{!!rightFilters?.length && (
							<div className={styles.right}>{rightFilters.map(renderFilter)}</div>
						)}
						{resetFilters && <MiniButton type="reset" caption="Reset" className={styles.reset} />}
					</form>
				)}
			</div>
			<div ref={contentRef} className={styles.content}>
				<table
					className={cn({
						[styles.firstSticky]: firstSticky,
						[styles.lastSticky]: lastSticky,
					})}
				>
					<thead>
						<tr>
							{columns.map(({ key, caption, width }) => {
								const style: CSSProperties = {}
								if (width) {
									const tdWidth = typeof width === "number" ? `${width}px` : width
									style.minWidth = tdWidth
									style.width = tdWidth
									style.maxWidth = tdWidth
								}
								return (
									<td key={`table-${tableId}-head-${key as string}`} style={style}>
										{caption}
									</td>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{rows.map((row, index) => (
							<tr key={`table-${tableId}-row-${row[id]}`}>
								{columns.map(({ key, render, subline, align, actions }) => (
									<td
										key={`table-${tableId}-row-${row[id]}-cell-${key as string}`}
										className={cn({
											[styles.middle]: align === ETableColumnAlign.MIDDLE,
											[styles.bottom]: align === ETableColumnAlign.BOTTOM,
										})}
									>
										<div>
											{render ? render(row, key as string, index) : row[key]}
											{actions?.(row, key as string, index).map(
												({ icon, caption, visible, disabled, onClick }, actionIndex) => {
													if (visible === false) return null
													return (
														<button
															type="button"
															key={`table-${tableId}-row-${row[id]}-cell-${
																key as string
															}-action-${actionIndex}`}
															disabled={disabled}
															onClick={() => onClick?.(row, key as string, index)}
														>
															{icon && <i className={`ai ${icon}`} />}
															{caption && <span>{caption}</span>}
														</button>
													)
												},
											)}
										</div>
										{subline && (
											<div className={styles.subline}>{subline(row, key as string, index)}</div>
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div ref={scrollRef} className={getScrollClassName()}>
				<div />
			</div>
			{(!!footer || isScrollOverlay) && (
				<div ref={footerRef} className={getFooterClassName()}>
					{footer === "pagination" && (
						<>
							<NewSelect<number>
								options={limits.map(limit => ({ label: `Show by ${limit}`, value: limit }))}
								value={limit}
								controlClassName={styles.limits}
								onChange={handleSelectLimit}
							/>
							<div>
								{Array(pages)
									.fill(null)
									.map((_, index) => (
										<MiniButton
											key={`table-${tableId}-page-${index.toString()}`}
											caption={index + 1}
											disabled={index + 1 === page}
											data-page={index + 1}
											onClick={handleSelectPage}
										/>
									))}
							</div>
							<div>
								<button type="button" disabled={page <= 1} onClick={handleGoPrevious}>
									<i className="ai ai-chevron_left" />
								</button>
								<hr />
								<button type="button" disabled={page >= pages} onClick={handleGoNext}>
									<i className="ai ai-chevron_right" />
								</button>
							</div>
						</>
					)}
					{footer === "all" && (
						<button type="button" onClick={onAllClick}>
							View All
						</button>
					)}
				</div>
			)}
		</div>
	)
}
