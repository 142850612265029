import React from "react"
import { observer } from "mobx-react-lite"

import { SidebarMenuLevelsEnum } from "types/sidebarMenuLevels"

import ProfileLayout from "layouts/ProfileLayout"
import Table, {
	ETableColumnAlign,
	ETableFilterType,
	TTableColumn,
	TTableFilter,
} from "components/NewUI/Table"
import Badge, { BadgeColorEnum } from "components/UI/Badge"

type TStatus = "Completed" | "Pending" | "Failed"

const statusColor: Record<TStatus, BadgeColorEnum> = {
	Pending: BadgeColorEnum.ORANGE,
	Completed: BadgeColorEnum.GREEN,
	Failed: BadgeColorEnum.RED,
}

type TRow = {
	id: number
	date: string
	coin: string
	chainType: string
	quantity: number
	address: string
	txid: string
	status: TStatus
}

const data: TRow[] = [
	{
		id: 1,
		date: "2024-10-20T14:35:00Z",
		coin: "USDT",
		chainType: "ERC20",
		quantity: 100.5,
		address: "0x32Be343B94f860124dC4fEe278FDCBD38C102D88",
		txid: "0xa1b2c3d4e5f6789a0b1c2d3e4f5a6b7c8d9e0f1a2b3c4d5e6f7a8b9c0d1e2f3a",
		status: "Completed",
	},
	{
		id: 2,
		date: "2024-10-19T11:20:00Z",
		coin: "TRX",
		chainType: "TRC20",
		quantity: 0,
		address: "TXkFd7jJ6m7a8k9b1c2d3e4f5g6h7i8j9k0lmnpo",
		txid: "4b5c6d7e8f9g1a2b3c4d5e6f7h8i9j0klmnopqrs",
		status: "Pending",
	},
	{
		id: 3,
		date: "2024-10-18T09:15:00Z",
		coin: "TON",
		chainType: "TON",
		quantity: 0,
		address: "EQD2d3E4f5g6h7i8j9k0l1m2n3p4q5r6s7t8u9v",
		txid: "a9b8c7d6e5f4g3h2i1j0k9l8m7n6o5p4q3r2s1t",
		status: "Completed",
	},
	{
		id: 4,
		date: "2024-10-17T08:10:00Z",
		coin: "BTC",
		chainType: "TRC20",
		quantity: 0.015,
		address: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
		txid: "7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5o6p",
		status: "Failed",
	},
	{
		id: 5,
		date: "2024-10-16T07:00:00Z",
		coin: "USDT",
		chainType: "TRC20",
		quantity: 200,
		address: "TPsL5QGefi2DMPTfTL5SLmv7DivfNa",
		txid: "c9d8e7f6g5h4i3j2k1l0m9n8o7p6q5r4s3t2u1v0",
		status: "Completed",
	},
	{
		id: 6,
		date: "2024-10-15T06:30:00Z",
		coin: "ETH",
		chainType: "ERC20",
		quantity: 2.3,
		address: "0x2dC4fEe278FDCBD38C102D88B32Be343B94f860",
		txid: "5g6h7i8j9k0l1m2n3p4q5r6s7t8u9v0w1x2y3z4a",
		status: "Pending",
	},
	{
		id: 7,
		date: "2024-10-14T05:25:00Z",
		coin: "BNB",
		chainType: "BEP20",
		quantity: 0,
		address: "bnb1q9dcwg4pnlfy2l9c8e0wpqq5e3xkgjk4e3tp9d",
		txid: "3a4b5c6d7e8f9g1h2i3j4k5l6m7n8o9p0q1r2s3t",
		status: "Completed",
	},
	{
		id: 8,
		date: "2024-10-13T04:00:00Z",
		coin: "BTC",
		chainType: "Bitcoin",
		quantity: 0.012,
		address: "1B1aP2QGefi3DMPTfSL5TLmv8DivNa",
		txid: "8f9g1h2i3j4k5l6m7n8o9p0q1r2s3t4u5v6w7x8y",
		status: "Failed",
	},
	{
		id: 9,
		date: "2024-10-12T02:30:00Z",
		coin: "USDT",
		chainType: "ERC20",
		quantity: 150,
		address: "0x5Ee4fDc4B82Be343B94f870C278F102D8D",
		txid: "9g0h1i2j3k4l5m6n7o8p9q0r1s2t3u4v5w6x7y8z",
		status: "Completed",
	},
	{
		id: 10,
		date: "2024-10-11T01:45:00Z",
		coin: "ADA",
		chainType: "Cardano",
		quantity: 300,
		address: "addr1q9dcwg4pnlfy2l9c8e0wpqq5e3xkgjk4e3tp9d",
		txid: "0a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p6q7r8s9t",
		status: "Pending",
	},
	{
		id: 11,
		date: "2024-10-20T14:35:00Z",
		coin: "USDT",
		chainType: "ERC20",
		quantity: 100.5,
		address: "0x32Be343B94f860124dC4fEe278FDCBD38C102D88",
		txid: "0xa1b2c3d4e5f6789a0b1c2d3e4f5a6b7c8d9e0f1a2b3c4d5e6f7a8b9c0d1e2f3a",
		status: "Completed",
	},
	{
		id: 20,
		date: "2024-10-19T11:20:00Z",
		coin: "TRX",
		chainType: "TRC20",
		quantity: 500,
		address: "TXkFd7jJ6m7a8k9b1c2d3e4f5g6h7i8j9k0lmnpo",
		txid: "4b5c6d7e8f9g1a2b3c4d5e6f7h8i9j0klmnopqrs",
		status: "Pending",
	},
	{
		id: 30,
		date: "2024-10-18T09:15:00Z",
		coin: "TON",
		chainType: "TON",
		quantity: 75,
		address: "EQD2d3E4f5g6h7i8j9k0l1m2n3p4q5r6s7t8u9v",
		txid: "a9b8c7d6e5f4g3h2i1j0k9l8m7n6o5p4q3r2s1t",
		status: "Completed",
	},
	{
		id: 40,
		date: "2024-10-17T08:10:00Z",
		coin: "BTC",
		chainType: "TRC20",
		quantity: 0.015,
		address: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
		txid: "7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5o6p",
		status: "Failed",
	},
	{
		id: 50,
		date: "2024-10-16T07:00:00Z",
		coin: "USDT",
		chainType: "TRC20",
		quantity: 200,
		address: "TPsL5QGefi2DMPTfTL5SLmv7DivfNa",
		txid: "c9d8e7f6g5h4i3j2k1l0m9n8o7p6q5r4s3t2u1v0",
		status: "Completed",
	},
	{
		id: 60,
		date: "2024-10-15T06:30:00Z",
		coin: "ETH",
		chainType: "ERC20",
		quantity: 2.3,
		address: "0x2dC4fEe278FDCBD38C102D88B32Be343B94f860",
		txid: "5g6h7i8j9k0l1m2n3p4q5r6s7t8u9v0w1x2y3z4a",
		status: "Pending",
	},
	{
		id: 70,
		date: "2024-10-14T05:25:00Z",
		coin: "BNB",
		chainType: "BEP20",
		quantity: 10.75,
		address: "bnb1q9dcwg4pnlfy2l9c8e0wpqq5e3xkgjk4e3tp9d",
		txid: "3a4b5c6d7e8f9g1h2i3j4k5l6m7n8o9p0q1r2s3t",
		status: "Completed",
	},
	{
		id: 80,
		date: "2024-10-13T04:00:00Z",
		coin: "BTC",
		chainType: "Bitcoin",
		quantity: 0.012,
		address: "1B1aP2QGefi3DMPTfSL5TLmv8DivNa",
		txid: "8f9g1h2i3j4k5l6m7n8o9p0q1r2s3t4u5v6w7x8y",
		status: "Failed",
	},
	{
		id: 90,
		date: "2024-10-12T02:30:00Z",
		coin: "USDT",
		chainType: "ERC20",
		quantity: 150,
		address: "0x5Ee4fDc4B82Be343B94f870C278F102D8D",
		txid: "9g0h1i2j3k4l5m6n7o8p9q0r1s2t3u4v5w6x7y8z",
		status: "Completed",
	},
	{
		id: 101,
		date: "2024-10-11T01:45:00Z",
		coin: "ADA",
		chainType: "Cardano",
		quantity: 300,
		address: "addr1q9dcwg4pnlfy2l9c8e0wpqq5e3xkgjk4e3tp9d",
		txid: "0a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p6q7r8s9t",
		status: "Pending",
	},
	{
		id: 100,
		date: "2024-10-20T14:35:00Z",
		coin: "USDT",
		chainType: "ERC20",
		quantity: 100.5,
		address: "0x32Be343B94f860124dC4fEe278FDCBD38C102D88",
		txid: "0xa1b2c3d4e5f6789a0b1c2d3e4f5a6b7c8d9e0f1a2b3c4d5e6f7a8b9c0d1e2f3a",
		status: "Completed",
	},
	{
		id: 200,
		date: "2024-10-19T11:20:00Z",
		coin: "TRX",
		chainType: "TRC20",
		quantity: 500,
		address: "TXkFd7jJ6m7a8k9b1c2d3e4f5g6h7i8j9k0lmnpo",
		txid: "4b5c6d7e8f9g1a2b3c4d5e6f7h8i9j0klmnopqrs",
		status: "Pending",
	},
	{
		id: 300,
		date: "2024-10-18T09:15:00Z",
		coin: "TON",
		chainType: "TON",
		quantity: 75,
		address: "EQD2d3E4f5g6h7i8j9k0l1m2n3p4q5r6s7t8u9v",
		txid: "a9b8c7d6e5f4g3h2i1j0k9l8m7n6o5p4q3r2s1t",
		status: "Completed",
	},
	{
		id: 400,
		date: "2024-10-17T08:10:00Z",
		coin: "BTC",
		chainType: "TRC20",
		quantity: 0.015,
		address: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
		txid: "7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5o6p",
		status: "Failed",
	},
	{
		id: 500,
		date: "2024-10-16T07:00:00Z",
		coin: "USDT",
		chainType: "TRC20",
		quantity: 200,
		address: "TPsL5QGefi2DMPTfTL5SLmv7DivfNa",
		txid: "c9d8e7f6g5h4i3j2k1l0m9n8o7p6q5r4s3t2u1v0",
		status: "Completed",
	},
	{
		id: 600,
		date: "2024-10-15T06:30:00Z",
		coin: "ETH",
		chainType: "ERC20",
		quantity: 2.3,
		address: "0x2dC4fEe278FDCBD38C102D88B32Be343B94f860",
		txid: "5g6h7i8j9k0l1m2n3p4q5r6s7t8u9v0w1x2y3z4a",
		status: "Pending",
	},
	{
		id: 700,
		date: "2024-10-14T05:25:00Z",
		coin: "BNB",
		chainType: "BEP20",
		quantity: 10.75,
		address: "bnb1q9dcwg4pnlfy2l9c8e0wpqq5e3xkgjk4e3tp9d",
		txid: "3a4b5c6d7e8f9g1h2i3j4k5l6m7n8o9p0q1r2s3t",
		status: "Completed",
	},
	{
		id: 800,
		date: "2024-10-13T04:00:00Z",
		coin: "BTC",
		chainType: "Bitcoin",
		quantity: 0.012,
		address: "1B1aP2QGefi3DMPTfSL5TLmv8DivNa",
		txid: "8f9g1h2i3j4k5l6m7n8o9p0q1r2s3t4u5v6w7x8y",
		status: "Failed",
	},
	{
		id: 900,
		date: "2024-10-12T02:30:00Z",
		coin: "USDT",
		chainType: "ERC20",
		quantity: 150,
		address: "0x5Ee4fDc4B82Be343B94f870C278F102D8D",
		txid: "9g0h1i2j3k4l5m6n7o8p9q0r1s2t3u4v5w6x7y8z",
		status: "Completed",
	},
	{
		id: 1000,
		date: "2024-10-11T01:45:00Z",
		coin: "ADA",
		chainType: "Cardano",
		quantity: 300,
		address: "addr1q9dcwg4pnlfy2l9c8e0wpqq5e3xkgjk4e3tp9d",
		txid: "0a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p6q7r8s9t",
		status: "Pending",
	},
]

const TestTable: React.FC = () => {
	const columns: TTableColumn<TRow>[] = [
		{
			key: "date",
			caption: "Date",
			width: 150,
			render: ({ date }) => {
				const val = new Date(date)
				return `${val.getMonth() + 1}/${val.getDate()}/${val.getFullYear()}`
			},
		},
		{ key: "coin", caption: "Coin", width: 80 },
		{ key: "chainType", caption: "Chain Type", width: 150 },
		{
			key: "quantity",
			caption: "Quantity",
			width: 120,
			subline: ({ quantity }) => `≈$200`,
		},
		{
			key: "address",
			caption: "Address",
			render: ({ address }) => {
				if (address.length <= 16) return address
				const middle = address.length / 2
				return `${address.substring(0, 5)}...${address.substring(
					middle - 2,
					middle + 2,
				)}...${address.slice(-5)}`
			},
			actions: () => [
				{
					icon: "ai-share_outline",
					onClick: ({ address }) => console.log("share", address),
				},
				{
					icon: "ai-copy_new",
					onClick: ({ address }) => console.log("copy", address),
				},
			],
		},
		{
			key: "txid",
			caption: "Txid",
			render: ({ txid }) => {
				if (txid.length <= 11) return txid
				return `${txid.substring(0, 5)}...${txid.slice(-5)}`
			},
			actions: () => [
				{
					icon: "ai-share_outline",
					onClick: ({ txid }) => console.log("share", txid),
				},
				{
					icon: "ai-copy_new",
					onClick: ({ txid }) => console.log("copy", txid),
				},
			],
		},
		{
			key: "status",
			width: 100,
			caption: "Status",
			render: ({ status }) => (
				<Badge color={statusColor[status]} alpha>
					{status}
				</Badge>
			),
		},
		{
			key: "actions",
			width: 100,
			caption: "Actions",
			align: ETableColumnAlign.MIDDLE,
			actions: () => [
				{
					icon: "ai-info-circle",
					onClick: ({ id }) => console.log("close", id),
				},
				{
					icon: "ai-x_close",
					onClick: ({ id }) => console.log("info", id),
				},
			],
		},
	]

	const filters: TTableFilter<TRow>[] = [
		{
			key: "coin",
			type: ETableFilterType.SELECT,
			items: Array.from(new Set(data.map(({ coin }) => coin)))
				.sort()
				.map(coin => ({ value: coin, label: coin })),
		},
		{
			key: "chainType",
			type: ETableFilterType.STRING,
			placeholder: "Search",
			icon: "ai-search",
		},
		{
			key: "quantity",
			type: ETableFilterType.CHECKBOX,
			caption: "Hide empty",
			toRight: true,
			filter: (value: boolean, { quantity }) => (value ? quantity > 0 : true),
		},
		{
			key: "date",
			type: ETableFilterType.DATE,
			caption: "Date",
			minDate: new Date("2024-01-01"),
			maxDate: new Date(),
		},
	]

	return (
		<ProfileLayout sidebarMenuLevel={SidebarMenuLevelsEnum.Wallets}>
			<p style={{ padding: "0 40px" }}>
				Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
				been the industrys standard dummy text ever since the 1500s, when an unknown printer took a
				galley of type and scrambled it to make a type specimen book. It has survived not only five
				centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
				It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
				passages, and more recently with desktop publishing software like Aldus PageMaker including
				versions of Lorem Ipsum.
			</p>
			<p style={{ padding: "0 40px" }}>
				Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
				been the industrys standard dummy text ever since the 1500s, when an unknown printer took a
				galley of type and scrambled it to make a type specimen book. It has survived not only five
				centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
				It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
				passages, and more recently with desktop publishing software like Aldus PageMaker including
				versions of Lorem Ipsum.
			</p>

			<Table
				columns={columns}
				data={data}
				filters={filters}
				resetFilters
				firstSticky={!false}
				lastSticky={!false}
				footer="pagination"
			/>

			<p style={{ padding: "0 40px" }}>
				Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
				been the industrys standard dummy text ever since the 1500s, when an unknown printer took a
				galley of type and scrambled it to make a type specimen book. It has survived not only five
				centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
				It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
				passages, and more recently with desktop publishing software like Aldus PageMaker including
				versions of Lorem Ipsum.
			</p>

			<p style={{ padding: "0 40px" }}>
				Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
				been the industrys standard dummy text ever since the 1500s, when an unknown printer took a
				galley of type and scrambled it to make a type specimen book. It has survived not only five
				centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
				It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
				passages, and more recently with desktop publishing software like Aldus PageMaker including
				versions of Lorem Ipsum.
			</p>
		</ProfileLayout>
	)
}

export default observer(TestTable)
