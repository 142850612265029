import { cast, flow, Instance, types as t } from "mobx-state-tree"

import errorHandler from "utils/errorHandler"
import AccountService from "services/AccountService"
import DepositService from "services/DepositService"
import { Balance, IBalance } from "./Account"

const DepositStatus = t.model({
	id: t.number,
	label: t.string,
})

export const Deposit = t.model({
	amount: t.string,
	confirmations: t.maybeNull(t.number),
	currency: t.string,
	date: t.string,
	fee_value: t.string,
	status: DepositStatus,
	txid: t.maybeNull(t.string),
})

export type IDeposit = Instance<typeof Deposit>

const DepositMethodContract = t.model({
	name: t.maybeNull(t.string),
	type: t.maybeNull(t.string),
	address: t.maybeNull(t.string),
})

export type IDepositMethodContract = Instance<typeof DepositMethodContract>

const DepositMethodCurrency = t.model({
	code: t.string,
	name: t.string,
	// image_svg: t.maybeNull(t.string),
	// image_png: t.maybeNull(t.string),
})

export type IDepositMethodCurrency = Instance<typeof DepositMethodCurrency>

const DepositMethodNetwork = t.model({
	name: t.string,
})

export type IDepositMethodNetwork = Instance<typeof DepositMethodNetwork>

const DepositMethodConvertRate = t.model({
	price: t.string,
	valid_till: t.string,
})

export type IDepositMethodConvertRate = Instance<typeof DepositMethodConvertRate>

const Attribute = t.model({
	label: t.string,
	value: t.string,
	name: t.string,
	show_qr: t.boolean,
})

export type IAttribute = Instance<typeof Attribute>

export const DepositMethod = t.model({
	id: t.string,
	name: t.string,
	min_deposit: t.number,
	deposit_confirmations_need: t.number,
	currency: DepositMethodCurrency,
	network: t.maybeNull(DepositMethodNetwork),
	min_verification_level: t.maybeNull(t.number),
	is_deposit_enabled: t.maybeNull(t.boolean),
})

export type IDepositMethod = Instance<typeof DepositMethod>

const PreviousDeposits = t.model({
	results: t.optional(t.array(Deposit), []),
	isLoading: t.optional(t.boolean, false),
})

const DepositHistory = t.model({
	results: t.optional(t.array(Deposit), []),
	isLoading: t.optional(t.boolean, false),
})

export const DepositModel = t
	.model({
		currencies: t.optional(t.array(Balance), []),
		currentCurrencyCode: t.maybe(t.string),
		methods: t.optional(t.array(DepositMethod), []),
		currentMethodId: t.maybe(t.string),
		isLoading: t.optional(t.boolean, false),
		previousDeposits: t.optional(PreviousDeposits, {}),
		isDepositsLoading: t.optional(t.boolean, false),
		depositHistory: t.optional(DepositHistory, {}),
		depositsCount: t.optional(t.number, 0),
	})

	.actions(self => ({
		setCurrentMethod(method?: string) {
			self.currentMethodId = cast(method)
		},
		setCurrentCurrency(currency?: string) {
			self.currentCurrencyCode = cast(currency)
		},
	}))

	.views(self => ({
		get currentCurrency() {
			if (!self.currentCurrencyCode?.length) return null
			return self.currencies.find(({ code }) => code === self.currentCurrencyCode) || null
		},
		get currentMethod() {
			if (!self.currentMethodId?.length) return null
			return self.methods.find(({ id }) => id === self.currentMethodId) || null
		},
	}))

	.actions(self => ({
		loadCurrencies: flow(function* () {
			try {
				const balances: Awaited<ReturnType<typeof AccountService.getBalances>> =
					yield AccountService.getBalances({ account_type: "FUND" })
				self.currencies = cast(
					balances.map<IBalance>(balance =>
						cast({
							code: balance.code,
							balance: +balance.balance,
							reserve: +balance.reserve,
							available: +balance.available,
							converted: Object.fromEntries(
								Object.entries(balance.converted).map(([key, val]) => [key, +val]),
							),
							is_demo: false,
						}),
					),
				)
			} catch (err) {
				errorHandler(err)
			}
		}),

		loadMethods: flow(function* () {
			if (!self.currentCurrencyCode?.length) return
			try {
				self.isLoading = true
				self.setCurrentMethod(undefined)
				const methods: Awaited<ReturnType<typeof DepositService.getMethods>> =
					yield DepositService.getMethods({ currency: self.currentCurrencyCode })
				self.methods = cast(
					methods.map<IDepositMethod>(method =>
						cast({
							id: method.id,
							name: method.name,
							min_deposit: +method.min_deposit,
							deposit_confirmations_need: method.deposit_confirmations_need,
							currency: method.currency,
							network: method.network,
							min_verification_level: method.min_verification_level,
							is_deposit_enabled: method.is_deposit_enabled,
						}),
					),
				)
				self.currentMethodId = self.methods.find(({ is_deposit_enabled }) => is_deposit_enabled)?.id
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isLoading = false
			}
		}),

		loadPreviousDeposits: flow(function* () {
			try {
				self.previousDeposits.isLoading = true
				const data = yield DepositService.getDepositHistory()
				self.previousDeposits.results = cast(data.results)
			} catch (err) {
				errorHandler(err)
			} finally {
				self.previousDeposits.isLoading = false
			}
		}),

		loadDepositHistory: flow(function* (currency?: string, startDate?: string, endDate?: string) {
			self.depositHistory.isLoading = true
			try {
				const params: { currency?: string; start_time?: string; end_time?: string } = {
					currency: currency || "",
				}
				if (startDate) {
					params.start_time = startDate
				}
				if (endDate) {
					params.end_time = endDate
				}
				const data: Awaited<ReturnType<typeof DepositService.getDepositHistory>> =
					yield DepositService.getDepositHistory(params)

				self.depositHistory.results = cast(data.results)
				// self.depositsCount = data.count || 0;
			} catch (error) {
				errorHandler(error)
			} finally {
				self.depositHistory.isLoading = false
			}
		}),
	}))
