import ApiClient from "helpers/ApiClient"
import {
	ICreateWithdrawRequestBody,
	ICreateWithdrawResponse,
	IDetailsWithdrawResponse,
	IGetWithdrawLimitParams,
	IGetWithdrawMethodsParams,
	IHistoryWithdrawParams,
	IHistoryWithdrawResponse,
	TGetWithdrawMethodsResponse,
} from "types/withdrawal"

const WithdrawalService = {
	getMethods: (params: IGetWithdrawMethodsParams): Promise<TGetWithdrawMethodsResponse> =>
		ApiClient.get("web/withdraw/methods", params),
	createWithdrawal: (data: ICreateWithdrawRequestBody): Promise<ICreateWithdrawResponse> =>
		ApiClient.post("web/withdraw/create/request", data),
	getWithdrawDetails: (slug: string): Promise<IDetailsWithdrawResponse> =>
		ApiClient.get(`web/withdraw/create/${slug}/details`),
	cancelWithdraw: (slug: string): Promise<void> =>
		ApiClient.post(`web/withdraw/create/${slug}/cancel`),
	getWithdrawLimit: (params: IGetWithdrawLimitParams): Promise<void> =>
		ApiClient.get(`web/withdraw/withdraw-daily-limit`, params),
	getPreviousWithdraws: (params: IHistoryWithdrawParams): Promise<IHistoryWithdrawResponse> =>
		ApiClient.get("web/withdraw/history", params),
}

export default WithdrawalService
