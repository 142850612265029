import React, { CSSProperties, JSX, useState } from "react"
import { useIntl } from "react-intl"
import historyMessages from "messages/history"

import useModal, { TDefaultModalProps } from "hooks/useModal"

import Button from "components/UI/Button"

import styles from "styles/components/Modals/Confirm.module.scss"

const DEFAULT_TITLE = "Confirmation"
const DEFAULT_WIDTH = 425

type TParams = {
	title?: string | false
	text?: string
	width?: number
	content?: JSX.Element
	onConfirmCaption?: string
	onCancelCaption?: string
	onCancel?: () => void
}

type TProps = TDefaultModalProps &
	TParams & {
		onConfirm: () => Promise<boolean>
	}

const Modal: React.FC<TProps> = ({
	title = DEFAULT_TITLE,
	text,
	width = DEFAULT_WIDTH,
	content,
	onConfirmCaption,
	onCancelCaption,
	onConfirm,
	onCancel,
	onClose,
}) => {
	const [loading, setLoading] = useState<boolean>(false)

	const { formatMessage } = useIntl()

	const handleConfirmClick = async () => {
		try {
			setLoading(true)
			if (await onConfirm()) onClose?.()
		} finally {
			setLoading(false)
		}
	}

	const handleCancelClick = async () => {
		onCancel?.()
		onClose?.()
	}

	return (
		<div className={styles.confirmModal} style={{ "--width": `${width}px` } as CSSProperties}>
			{title !== false && (
				<div className={styles.header}>
					<span>{title}</span>
				</div>
			)}
			<div className={styles.content}>
				<p>{text}</p>
				{content}
			</div>
			<div className={styles.footer}>
				<Button
					label={onCancelCaption || formatMessage(historyMessages.active_orders_button_cancel)}
					disabled={loading}
					variant="outlined"
					onClick={handleCancelClick}
				/>
				<Button
					label={onConfirmCaption || formatMessage(historyMessages.active_orders_button_confirm)}
					isLoading={loading}
					onClick={handleConfirmClick}
				/>
			</div>
		</div>
	)
}

type TOptions = TParams & {
	onConfirm?: () => Promise<boolean | void> | boolean | void
}

const useConfirmModal = () => {
	const [open, close, preventClosing] = useModal(Modal)

	const openConfirmModal = async ({
		title = DEFAULT_TITLE,
		text,
		width,
		content,
		onConfirmCaption,
		onCancelCaption,
		onCancel,
		onConfirm,
	}: TOptions = {}): Promise<boolean> =>
		new Promise<boolean>(resolve => {
			open({
				title,
				text,
				width,
				content,
				onConfirmCaption,
				onCancelCaption,
				onCancel: () => {
					onCancel?.()
					resolve(false)
				},
				onConfirm: async () => {
					preventClosing(true)
					const result = (await onConfirm?.()) !== false
					preventClosing(false)
					if (result) resolve(true)
					return result
				},
			})
		})

	return { openConfirmModal, closeConfirmModal: close }
}

export default useConfirmModal
