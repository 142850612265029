import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import cn from "classnames"

import { routes } from "constants/routing"
import { EWithdrawLoading } from "types/withdrawal"
import { useMst } from "models/Root"
import messages from "messages/finance"
import useWindowSize from "hooks/useWindowSize"
import useLocaleNavigate from "hooks/useLocaleNavigate"

import CurrencySelect, { IOption } from "components/UI/CurrencySelect"
import { ISelectOption as IUISelectOption } from "components/UI/Select"

import styles from "styles/components/DepositWithdrawal.module.scss"

import LimitInfo from "./LimitInfo"
import HelpInfo from "./HelpInfo"

const CoinStep: React.FC = () => {
	const {
		withdrawal: { currencies, currentCurrency, getIsLoading, currentMethod },
		render,
	} = useMst()
	const { formatMessage, formatNumber } = useIntl()
	const { mobile } = useWindowSize()
	const localeNavigate = useLocaleNavigate()

	const currencyOptions = useMemo(
		() =>
			[...currencies]
				.filter(({ available }) => available)
				.sort((a, b) => a.code.localeCompare(b.code))
				.map<IOption>(({ code, available }) => ({
					label: { code, available: available.toString() },
					value: code,
				})),
		[currencies.length],
	)

	const currentOption = currencyOptions.find(({ value }) => value === currentCurrency?.code)

	const handleSelectChange = useCallback((e: IUISelectOption) => {
		const value = e.value.toUpperCase()
		localeNavigate(routes.profile.getWithdrawCurrency(value))
	}, [])

	return (
		<div className={styles.step_container}>
			<div className={styles.step_container_section}>
				<div className={styles.step_info}>
					<span className={styles.step_info_title}>
						1.&nbsp;
						{formatMessage(messages.select_currency)}
					</span>
				</div>
				<div className={styles.form_container}>
					<CurrencySelect
						onSelectChange={handleSelectChange}
						options={currencyOptions}
						value={currentOption}
						autoFocus
						hideIcons
						isLoading={getIsLoading(EWithdrawLoading.CURRENCIES)}
						disabled={!currencyOptions.length}
					/>
					{currentCurrency ? (
						<div className={styles.form_group_container}>
							<div className={styles.form_group}>
								<span className={styles.form_group_value}>
									{formatMessage(messages.overall_balance)}:
								</span>
								<span className={styles.form_group_value}>{formatMessage(messages.reserve)}:</span>
								<span className={styles.form_group_value}>
									{formatMessage(messages.available)}:
								</span>
							</div>
							<div className={styles.form_group}>
								<span className={cn(styles.form_group_value, styles.primary)}>
									{formatNumber(currentCurrency.balance, {
										maximumFractionDigits: 8,
										useGrouping: false,
									})}
									&nbsp;{currentCurrency.code}
								</span>
								<span className={styles.form_group_value}>
									{formatNumber(currentCurrency.reserve, {
										maximumFractionDigits: 8,
										useGrouping: false,
									})}
									&nbsp;{currentCurrency.code}
								</span>
								<span className={cn(styles.form_group_value, styles.primary)}>
									{formatNumber(currentCurrency.available, {
										maximumFractionDigits: 8,
										useGrouping: false,
									})}
									&nbsp;{currentCurrency.code}
								</span>
							</div>
						</div>
					) : null}
				</div>
				<div className={styles.limit_container}>
					<LimitInfo />
				</div>
			</div>
			{!mobile && render.supportCenter && <HelpInfo />}
		</div>
	)
}

export default observer(CoinStep)
