import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useIntl } from "react-intl"
import { useParams } from "react-router"
import { observer } from "mobx-react-lite"

import { useMst } from "models/Root"
import messages from "messages/finance"
import { SidebarMenuLevelsEnum } from "types/sidebarMenuLevels"
import { queryVars } from "constants/query"
import { IBalance } from "models/Account"
import { getPageTitle } from "helpers/global"

import ProfileLayout from "layouts/ProfileLayout"
import DepositForm from "components/Deposit/DepositForm"
import PreviousDeposits from "components/Deposit/PreviousDeposits"

import styles from "styles/pages/DepositWithdrawal.module.scss"

const Deposit: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		deposit: { currencies, loadCurrencies, setCurrentCurrency, loadPreviousDeposits },
	} = useMst()
	const { currency } = useParams<{ [queryVars.currency]: string }>()

	useEffect(() => {
		loadCurrencies()
		loadPreviousDeposits()
	}, [])

	useEffect(() => {
		if (currencies.length && currency) {
			const curr: IBalance | undefined = currencies.find(
				(c: IBalance) => c.code?.toUpperCase() === currency.toUpperCase(),
			)
			setCurrentCurrency(curr?.code)
		}
	}, [currencies.length, currency])

	return (
		<ProfileLayout sidebarMenuLevel={SidebarMenuLevelsEnum.Wallets}>
			<div className={styles.container}>
				<Helmet title={getPageTitle(`${formatMessage(messages.deposit)} - ${currency}`)} />
				<DepositForm />
				<PreviousDeposits />
			</div>
			{/*<WebSocket events={[WSListenEventEnum.WALLETS]} />*/}
		</ProfileLayout>
	)
}

export default observer(Deposit)
