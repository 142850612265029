/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

import {
	EBybitMessageTopic,
	EBybitMessageType,
	TBybitOrderbook,
	TBybitWebSocketOpenOrder,
	TBybitWebSocketTrade,
	TBybitWebSocketWallet,
} from "types/bybit"
import { EOrderOpenStatus } from "types/orders"
import BybitWebSocket, { TBybitWebSocketListeners } from "helpers/bybitWebSocket"
import { useMst } from "models/Root"
import Success from "assets/icons/toast/Success"

const AUTH_ATTEMPTS_COUNT = 10
const AUTH_ATTEMPTS_DELAY_MS = 2000

interface UseBybitWebSocketProps {
	baseUrl: string
	apiKey?: string
	expires?: number
	signature?: string
	channels: string[]
	disabled?: boolean
}

const deepEqual = (obj1: any, obj2: any): boolean => {
	if (obj1 === obj2) return true
	if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
		return false
	}

	const keys1 = Object.keys(obj1)
	const keys2 = Object.keys(obj2)

	if (keys1.length !== keys2.length) return false

	for (const key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false
	}
	return true
}

const useBybitWebSocket = ({
	baseUrl,
	apiKey,
	expires,
	signature,
	channels,
	disabled,
}: UseBybitWebSocketProps) => {
	const wsRef = useRef<BybitWebSocket | null>(null)
	const [klineData, setKlineData] = useState<any | null>(null)
	const {
		terminal,
		bybit: { setTicker, patchRecentTrades, patchSpotBalances, loadBorrowQuota },
		tickers: { updateTickers },
		history: { updateOrder },
		account: { patchBalances, loadAggregatedBalance },
	} = useMst()

	const lastWalletData = useRef<any>(null)

	useEffect(() => {
		if (disabled) return undefined

		const bybitWs = new BybitWebSocket({ baseUrl, apiKey, expires, signature })

		const onMessage: TBybitWebSocketListeners["message"] = (message: {
			topic: string
			type: EBybitMessageType
			data: any
		}) => {
			if (message.topic?.startsWith(EBybitMessageTopic.ORDERBOOK)) {
				const { b: bids, a: asks } = message.data as TBybitOrderbook
				if (message.type === EBybitMessageType.SNAPSHOT) {
					terminal.updateOrderBook(bids, asks)
				} else if (message.type === EBybitMessageType.DELTA) {
					terminal.patchOrderBook(bids, asks)
				}
			} else if (message.topic?.startsWith(EBybitMessageTopic.TICKERS)) {
				if (message.type === EBybitMessageType.SNAPSHOT) {
					setTicker(message.data)
					updateTickers(message.data)
				}
			} else if (message.topic?.startsWith(EBybitMessageTopic.PUBLIC_TRADE)) {
				const trades = message.data as TBybitWebSocketTrade[]
				patchRecentTrades(trades)
			} else if (message.topic?.startsWith(EBybitMessageTopic.KLINE)) {
				setKlineData(message.data[0])
			} else if (message.topic?.startsWith(EBybitMessageTopic.WALLET)) {
				const items = message.data as TBybitWebSocketWallet[]
				const data = items.find(({ accountType }) => accountType === "UNIFIED")
				if (data) {
					patchSpotBalances(data)
					patchBalances(data)
					const dataString = JSON.stringify({
						totalWalletBalance: data.totalWalletBalance,
						coins: data.coin.map(coin => ({
							coin: coin.coin,
							walletBalance: coin.walletBalance,
							collateralSwitch: coin.collateralSwitch,
						})),
					})

					if (lastWalletData.current !== dataString && lastWalletData.current !== null) {
						loadAggregatedBalance()
						loadBorrowQuota()
						lastWalletData.current = dataString
					}
				}
			} else if (message.topic?.startsWith(EBybitMessageTopic.ORDER)) {
				const orders = message.data as TBybitWebSocketOpenOrder[]
				updateOrder(orders)
				loadBorrowQuota()

				orders.forEach(({ category, feeCurrency, orderStatus, orderType, qty, side }) => {
					if (category !== "spot") return
					if (Object.values(EOrderOpenStatus).includes(orderStatus as EOrderOpenStatus)) return
					toast.success(
						<div>
							<div>Success</div>
							<div className="Toastify__toast-subtext">
								{orderType} {side} {qty} {feeCurrency} Order has been {orderStatus}
							</div>
						</div>,
						{
							position: "bottom-left",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "dark",
							icon: <Success />,
						},
					)
				})
			}
		}

		bybitWs
			.connect()
			.then(async () => {
				bybitWs.startPingInterval()
				if (apiKey && expires && signature) {
					let result: string | true = ""
					for (let i = 0; i < AUTH_ATTEMPTS_COUNT; i++) {
						result = await bybitWs.authenticate()
						if (result === true) break
						if (AUTH_ATTEMPTS_DELAY_MS > 0)
							await new Promise(resolve => {
								setTimeout(resolve, AUTH_ATTEMPTS_DELAY_MS)
							})
					}
					if (result === true) bybitWs.subscribe(...channels)
					else throw new Error(result)
				} else {
					bybitWs.subscribe(...channels)
				}
				bybitWs.on("message", onMessage)
			})
			.catch(error => console.error("Failed to connect:", error))

		wsRef.current = bybitWs

		return () => {
			bybitWs.off("message", onMessage)
			wsRef.current?.close()
		}
	}, [baseUrl, apiKey, expires, signature, channels.join(), disabled])

	return { bybitSocket: wsRef.current, klineData }
}

export default useBybitWebSocket
