import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { queryVars } from "constants/query"
import { SidebarMenuLevelsEnum } from "types/sidebarMenuLevels"
import { getPageTitle } from "helpers/global"
import { useMst } from "models/Root"
import commonMessages from "messages/common"
import { IBalance } from "models/Account"

import ProfileLayout from "layouts/ProfileLayout"
import PreviousWithdrawals from "components/Withdrawal/PreviousWithdrawals"
import WithdrawalForm from "components/Withdrawal/WithdrawalForm"

import styles from "styles/pages/DepositWithdrawal.module.scss"

const Withdrawal: React.FC = () => {
	const {
		withdrawal: { currencies, loadCurrencies, setCurrentCurrency, loadPreviousWithdraws },
	} = useMst()
	const { formatMessage } = useIntl()
	const { currency } = useParams<{ [queryVars.currency]: string }>()

	useEffect(() => {
		loadCurrencies()
		loadPreviousWithdraws({
			[queryVars.page]: 1,
			[queryVars.page_size]: 15,
		})
	}, [])

	// useEffect(() => {
	// 	if (currency) loadWithdrawLimit({ currency })
	// }, [currency])

	useEffect(() => {
		if (currencies.length && currency) {
			const curr: IBalance | undefined = currencies.find(
				(c: IBalance) => c.code.toUpperCase() === currency.toUpperCase(),
			)
			setCurrentCurrency(curr?.code)
		}
	}, [currencies.length, currency])

	return (
		<ProfileLayout sidebarMenuLevel={SidebarMenuLevelsEnum.Wallets}>
			<div className={styles.container}>
				<Helmet title={getPageTitle(`${formatMessage(commonMessages.withdraw)} - ${currency}`)} />
				<WithdrawalForm />
				<PreviousWithdrawals />
			</div>
			{/*<WebSocket events={[WSListenEventEnum.WALLETS]} />*/}
		</ProfileLayout>
	)
}

export default observer(Withdrawal)
