import React, { useCallback, useState } from "react"
import cn from "classnames"
import { useIntl } from "react-intl"
import dayjs from "utils/dayjs"

import { EWithdrawalStatuses } from "types/withdrawal"
import { IHistoricalWithdrawal } from "models/Withdrawal"
import useCopyClick from "hooks/useCopyClick"
import financeMessages from "messages/finance"

import ButtonMicro from "components/UI/ButtonMicro"
import { TableData, TableRow, TableRowAdvancedContainer } from "components/UI/Table"
import { OutlinedBadge } from "components/UI/Badge"

import styles from "styles/components/DepositWithdrawal.module.scss"

import { badgeColor } from "./shared"

interface Props {
	withdraw: IHistoricalWithdrawal
	locale: string
	onCancel: (amount: string, currencyCode: string, slug: string) => void
}

const PreviousWithdrawalRow: React.FC<Props> = React.memo(({ withdraw, locale, onCancel }) => {
	const { formatMessage, formatNumber } = useIntl()
	const [isExpanded, setIsExpanded] = useState<boolean>(false)
	const statusCaption = withdraw.status.replace(/([A-Z])/g, ` $1`).toLowerCase()
	const copyClick = useCopyClick()
	const addressValue = withdraw.address
	const txid = withdraw.txid || "--"

	const handleCopyTXIDToClipboard = useCallback(() => {
		if (!txid || txid === "--") return
		copyClick(
			txid,
			formatMessage(financeMessages.copied_to_clipboard, {
				label: "TX",
			}),
		)
	}, [txid])

	const handleCopyAddressToClipboard = useCallback(() => {
		if (addressValue) {
			copyClick(addressValue)
		}
	}, [addressValue])

	const handleExpand = useCallback(() => {
		setIsExpanded(prevState => !prevState)
	}, [])

	return (
		<TableRowAdvancedContainer active={isExpanded}>
			<TableRow isExpandActive={isExpanded}>
				<TableData>
					{dayjs(withdraw.created_at).format("DD/MM/YYYY")}&nbsp;
					<span className={styles.table_data_grey_colored}>
						{dayjs(withdraw.created_at).format("HH:mm")}
					</span>
				</TableData>
				<TableData>
					{`${withdraw.currency ?? ""}${!!withdraw.chain && ` (${withdraw.chain})`}`}
				</TableData>
				<TableData>
					{formatNumber(+withdraw.amount, {
						useGrouping: false,
						minimumFractionDigits: 8,
						maximumFractionDigits: 8,
					})}
				</TableData>
				<TableData align="right" maxWidth="150px">
					<OutlinedBadge color={badgeColor(withdraw.status as EWithdrawalStatuses)}>
						{statusCaption}
					</OutlinedBadge>
				</TableData>
				<TableData icon onClick={handleExpand}>
					<ButtonMicro
						className={cn(styles.expand_row_icon_button, {
							[styles.active]: isExpanded,
						})}
					>
						<i className="ai ai-arrow_down" />
					</ButtonMicro>
				</TableData>
			</TableRow>
			{isExpanded && (
				<div className={styles.withdraw_details}>
					{addressValue && (
						<div className={styles.withdraw_details_txid}>
							<span>Address to: {addressValue}</span>
							<ButtonMicro onClick={handleCopyAddressToClipboard} primary>
								<i className="ai ai-copy_new" />
							</ButtonMicro>
						</div>
					)}
					<div className={styles.withdraw_details_txid}>
						<span>TX:</span>
						{txid}
						<ButtonMicro onClick={handleCopyTXIDToClipboard} primary>
							<i className="ai ai-copy_new" />
						</ButtonMicro>
					</div>
					{withdraw.status === EWithdrawalStatuses.PENDING && txid === "--" && (
						<div className={styles.withdraw_details_moderation}>
							<i className="ai ai-clock" />
							{formatMessage(financeMessages.withdrawal_was_sent_to_moderation)}
						</div>
					)}
				</div>
			)}
		</TableRowAdvancedContainer>
	)
})

export default PreviousWithdrawalRow
