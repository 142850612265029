export enum DepositColorsEnum {
	Blue = "blue",
	Green = "green",
	Orange = "orange",
	Red = "red",
}

export enum DepositStatusesEnum {
	Pending = 1, //  # when waiting for confirmation
	Moderation = 2, //  # when manual or additional moderation need (no usage)
	Confirmed = 5, //  # when on balance
	Rejected = 6, //  # when impossible to approve
}

export interface IGetDepositAddressParams {
	deposit_method: string
}

export interface IGetDepositAddressResponse {
	coin: string
	chains: {
		chain_type: string
		address_deposit: string
		tag_deposit: string
		batch_release_limit: string
	}
}

export interface IGetDepositHistoryParams {
	currency?: string
	start_time?: string
	end_time?: string
}

export interface IGetDepositMethodsParams {
	currency: string
}

export type TGetDepositMethodsResponse = {
	id: string
	name: string
	min_deposit: string
	deposit_confirmations_need: number
	currency: {
		code: string
		name: string
	}
	network: {
		name: string
	}
	min_verification_level: number
	is_deposit_enabled: boolean
}[]

export interface IGetDepositHistoryResponse {
	results: {
		date: string
		amount: string
		currency: string
		status: {
			id: number
			label: string
		}
		fee_value: string
		confirmations: number
		txid: string
		deposit_type: string
	}[]
	cursor: string
}
