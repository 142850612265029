/* eslint-disable no-unused-expressions */
import React, { CSSProperties, ReactNode } from "react"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"

import styles from "./miniButton.module.scss"

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	caption?: string | number
	icon?: ReactNode | TAlphaIcon
	iconSize?: number
	bordered?: boolean
	disabled?: boolean
}

export const MiniButton: React.FC<IProps> = ({
	caption,
	icon,
	iconSize,
	bordered,
	className,
	...props
}) => {
	const style: Record<string, string> = {}

	if (iconSize) {
		const remInPx = parseFloat(getComputedStyle(document.documentElement).fontSize)
		style["--icon-size"] = `${iconSize / remInPx}rem`
	}

	return (
		<button
			type="button"
			style={Object.keys(style).length ? (style as CSSProperties) : undefined}
			className={cn(styles.miniButton, { [styles.bordered]: bordered }, className)}
			{...props}
		>
			{typeof icon === "string" ? <i className={cn("ai", icon)} /> : icon}
			{!!caption && <span>{caption}</span>}
		</button>
	)
}
