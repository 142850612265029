import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"

import { EWithdrawLoading } from "types/withdrawal"
import { SecureTokenTypeEnum } from "types/secureToken"
import dayjs from "utils/dayjs"
import { useMst } from "models/Root"
import { getPageTitle } from "helpers/global"
import financeMessages from "messages/finance"

import { ScreenCanceled, ScreenConfirmed, ScreenTimeHasExpired } from "components/StatusScreens"
import LoadingSpinner from "components/UI/LoadingSpinner"
import { NotFoundError } from "components/Error"
import ConfirmForm from "components/ConfirmWithdrawal/ConfirmForm"

import styles from "styles/components/ConfirmationComponents.module.scss"

const ConfirmWithdrawal: React.FC = () => {
	const { slug } = useParams<{ slug: string }>()
	const {
		withdrawal: { withdraw, loadWithdrawal, getIsLoading, cancelWithdraw, setWithdraw },
		account: { loadBalances },
	} = useMst()
	const { formatMessage } = useIntl()
	const [tokenType, setTokenType] = useState<SecureTokenTypeEnum>()
	const [isCancelled, setIsCancelled] = useState<boolean>(false)
	const title = getPageTitle(formatMessage(financeMessages.withdraw_confirming))

	useEffect(() => {
		if (slug) loadWithdrawal(slug)
	}, [slug])

	useEffect(() => {
		if (!withdraw) return
		if (withdraw.is_totp_required && !withdraw.is_totp_ok) {
			setTokenType(SecureTokenTypeEnum.OTPCODE)
		} else if (withdraw.is_pincode_required && !withdraw.is_pincode_ok) {
			setTokenType(SecureTokenTypeEnum.PINCODE)
		}
	}, [
		withdraw?.is_totp_required,
		withdraw?.is_pincode_required,
		withdraw?.is_totp_ok,
		withdraw?.is_pincode_ok,
	])

	const handleSuccess = (info: any) => {
		setWithdraw(info)
		loadBalances()
	}

	const handleCancel = async () => {
		if (!slug) return
		const result = await cancelWithdraw(slug)
		setIsCancelled(result)
	}

	const renderContent = (): JSX.Element | null => {
		if (getIsLoading(EWithdrawLoading.DETAILS)) return <LoadingSpinner />
		if (!withdraw) return <NotFoundError />
		if (isCancelled) {
			return (
				<ScreenCanceled
					type="withdrawal"
					currency={withdraw.currency?.code}
					amount={withdraw.amount}
				/>
			)
		}
		if (dayjs(withdraw.deadline_at).isBefore(Date.now()) && !withdraw.is_ok) {
			return (
				<ScreenTimeHasExpired
					type="withdrawal"
					currency={withdraw.currency.code || ""}
					amount={withdraw.amount}
				/>
			)
		}
		if (withdraw.is_ok) {
			return (
				<ScreenConfirmed
					type="withdrawal"
					currency={withdraw.currency.code}
					amount={withdraw.amount}
				/>
			)
		}
		return (
			<ConfirmForm
				info={withdraw}
				tokenType={tokenType}
				onSuccess={handleSuccess}
				onCancel={handleCancel}
			/>
		)
	}

	return (
		<>
			<Helmet
				title={title}
				meta={[
					{ name: "description", content: title },
					{ property: "og:title", content: title },
					{ property: "twitter:title", content: title },
					{ property: "og:description", content: title },
					{ name: "twitter:description", content: title },
				]}
			/>
			<div className={styles.container}>{renderContent()}</div>
		</>
	)
}

export default observer(ConfirmWithdrawal)
