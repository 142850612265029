import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import Helmet from "react-helmet"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import { EWithdrawLoading } from "types/withdrawal"
import { queryVars } from "constants/query"
import queryParamsToObject from "utils/queryParamsToObject"
import { getPageTitle } from "helpers/global"
import useParamQuery from "hooks/useSearchQuery"
import useWindowSize from "hooks/useWindowSize"
import { useMst } from "models/Root"
// import { IWithdrawalDetails } from "models/Withdrawal"
import commonMessages from "messages/common"
import messages from "messages/history"

import Pagination from "components/UI/Pagination"
import LoadingSpinner from "components/UI/LoadingSpinner"
import CurrencySelect, { IOption } from "components/UI/CurrencySelect"
import NoRowsMessage from "components/Table/NoRowsMessage"
import { IHeaderColumn } from "components/UI/Table/Table"
import { Table } from "components/UI/Table"
import DateRangePicker, { IChangeDateRange, IDateRange } from "components/UI/DateRangePicker"
import Button from "components/UI/Button"

import pageStyles from "styles/pages/Page.module.scss"
import styles from "styles/components/FinanceHistory.module.scss"

import WithdrawMobileRow from "./WithdrawMobileRow"
import WithdrawRow from "./WithdrawRow"
// import CancelWithdrawModal from "./CancelWithdrawModal"
import WithdrawRowSkeleton from "./WithdrawRowSkeleton"

const DEFAULT_PAGE_SIZE = 15

type TFilters = {
	currency?: string
	date?: IDateRange
	page: number
}

type TParams = Partial<Record<"currency" | "created_at" | "page", string>>

const dateToStr = (date: Date) => new Date(date).toISOString().slice(0, 10)

const paramsToFilters = (params: TParams): TFilters => ({
	page: +(params.page || 1),
	currency: params.currency,
	date: !params.created_at
		? undefined
		: {
				startDate: new Date(params.created_at.split(",")[0]),
				endDate: new Date(params.created_at.split(",")[1]),
				key: "selection",
		  },
})

const filtersToParams = (filters: TFilters, forcePage?: boolean): TParams => {
	const params: TParams = {}
	if (filters.page > 1) params.page = filters.page.toString()
	else if (forcePage) params.page = "1"
	if (filters.date) {
		params.created_at = `${dateToStr(filters.date.startDate)},${dateToStr(filters.date.endDate)}`
	}
	if (filters.currency) params.currency = filters.currency as string
	return params
}

const filtersToUrlParams = (filters: TFilters): string => {
	const params: string[] = Object.entries(filtersToParams(filters)).map(
		([name, value]) => `${name}=${value}`,
	)
	if (!params.length) return ""
	return `?${params.join("&")}`
}

const Withdraws = () => {
	const {
		withdrawal: {
			previousWithdraws: withdraws,
			previousWithdrawsCount: withdrawsCount,
			loadPreviousWithdraws,
			getIsLoading,
			cancelWithdraw,
			currencies,
			loadCurrencies,
		},
		terminal: { pair },
	} = useMst()

	const isWithdrawsLoading = getIsLoading(EWithdrawLoading.HISTORY)

	const query = useParamQuery()
	const navigate = useNavigate()
	const { desktop } = useWindowSize()
	const { formatMessage } = useIntl()
	// const [modalOpened, setModalOpened] = useState(false);

	const datePickerRef = React.useRef<HTMLDivElement>(null)

	// const [withdrawToCancel, setWithdrawToCancel] = useState<IWithdrawalDetails>();
	const [filters, _setFilters] = useState<TFilters>({
		...paramsToFilters(queryParamsToObject(query)),
		page: +(query.get(queryVars.page) ?? 1),
	})
	const updateFilter = useCallback(
		(data: Partial<TFilters>) => {
			const newFilters: TFilters = { ...filters, page: 1, ...data }
			_setFilters(newFilters)
			return newFilters
		},
		[filters],
	)

	const loadWithdraws = (params: Record<string, string>) =>
		loadPreviousWithdraws({ ...params, [queryVars.page_size]: DEFAULT_PAGE_SIZE })

	const applyFilters = useCallback((newFilters: TFilters) => {
		navigate({ search: filtersToUrlParams(newFilters) })
		loadWithdraws(filtersToParams(newFilters, true))
	}, [])

	const length = withdraws.length

	const currenciesOptions: IOption[] =
		currencies?.map(
			(currency): IOption => ({
				label: {
					code: currency.code,
					name: currency.name,
					image_png: currency.image_png ?? "",
					image_svg: currency.image_svg ?? "",
				},
				value: currency.code,
			}),
		) || []

	const selectedCurrency = currenciesOptions?.find(
		currencyOption => currencyOption.value === filters.currency,
	)

	useEffect(() => {
		const params = queryParamsToObject(query)
		loadCurrencies()
		loadWithdraws({ page: "1", ...params })
	}, [])

	const handlePairSelect = useCallback(
		(e: any) => {
			const currency = e?.value || null
			if (currency === filters.currency) return
			updateFilter({ currency: currency })
		},
		[filters],
	)

	const handleDateRangeChange = useCallback(
		({ selection }: IChangeDateRange) => updateFilter({ date: selection }),
		[updateFilter],
	)
	const handleRangeClear = useCallback(() => updateFilter({ date: undefined }), [updateFilter])

	const handleSearch = useCallback(() => applyFilters(filters), [filters])

	const handlePaginationClick = useCallback(
		(page: number) => {
			if (page === filters.page) return
			const newFilters = updateFilter({ page: page })
			applyFilters(newFilters)
		},
		[filters],
	)

	const handleCancelClick = useCallback(
		(e: any) => {
			// const { id } = e.currentTarget.dataset;
			//
			// const withdrawToCancel = withdraws.find(
			//   (withdraw: IWithdrawalDetails) => withdraw.id === parseInt(id, 10)
			// );
			// setWithdrawToCancel(withdrawToCancel);
			// setModalOpened(true);
		},
		[withdraws],
	)

	// const handleCloseModal = useCallback(() => {
	//   setModalOpened(false);
	// }, []);

	// const handleModalConfirm = useCallback(async () => {
	//   if (!withdrawToCancel?.slug) return;
	//   await cancelWithdraw(withdrawToCancel.slug);
	//   handleCloseModal();
	// }, [withdrawToCancel]);

	const columns: IHeaderColumn[] = [
		{
			name: "date",
			label: formatMessage(messages.orders_table_date),
			width: "85px",
		},
		{
			name: "channel",
			label: formatMessage(messages.deposits_table_channel),
			width: "80px",
		},
		{
			name: "amount",
			label: formatMessage(messages.orders_table_amount),
			align: "right",
			width: "100px",
		},
		{
			name: "fee",
			label: formatMessage(messages.trades_table_fee),
			width: "100px",
		},
		{
			name: "transaction-id",
			label: formatMessage(messages.deposits_table_transaction),
			minWidth: "300px",
		},
		{
			name: "status",
			label: formatMessage(messages.orders_table_status),
			width: "120px",
			align: "center",
		},
	]

	const rangeValue = useMemo<[IDateRange] | undefined>(
		() => (!filters.date ? undefined : [filters.date as IDateRange]),
		[filters.date],
	)

	return (
		<>
			<Helmet title={getPageTitle(formatMessage(messages.withdraws_history))} />
			<div className={pageStyles.filters}>
				<div ref={datePickerRef}>
					<DateRangePicker
						ranges={rangeValue}
						onChange={handleDateRangeChange}
						onRangeClear={handleRangeClear}
						containerClassName={pageStyles.date_picker}
						staticRanges={[]}
						inputRanges={[]}
					/>
				</div>
				<div className={pageStyles.filter_select}>
					<CurrencySelect
						onSelectChange={handlePairSelect}
						options={currenciesOptions}
						value={selectedCurrency}
						autoFocus
						withoutLabel
						isClearable
						mini
						placeholder={formatMessage(messages.deposits_table_currency)}
					/>
				</div>
				<div className={pageStyles.filters_buttons}>
					<Button
						variant="filled"
						color="primary"
						label={formatMessage(commonMessages.search)}
						mini
						onClick={handleSearch}
						isLoading={isWithdrawsLoading}
					/>
				</div>
			</div>
			{desktop ? (
				<Table
					stripped
					header={{
						columns,
					}}
				>
					{isWithdrawsLoading ? (
						[...new Array(DEFAULT_PAGE_SIZE)].map((_, i: number) => <WithdrawRowSkeleton key={i} />)
					) : length > 0 ? (
						withdraws.map(withdraw => (
							<WithdrawRow
								withdraw={withdraw}
								key={withdraw.id}
								onCancelClick={handleCancelClick}
							/>
						))
					) : (
						<NoRowsMessage disableHover />
					)}
				</Table>
			) : (
				<div className={styles.mobile_container}>
					{isWithdrawsLoading ? (
						<LoadingSpinner />
					) : length > 0 ? (
						withdraws.map(withdraw => (
							<WithdrawMobileRow
								key={withdraw.id}
								withdraw={withdraw}
								onCancelClick={handleCancelClick}
							/>
						))
					) : (
						<NoRowsMessage disableHover />
					)}
				</div>
			)}
			<div className={styles.pagination_container}>
				<Pagination
					count={Math.ceil(withdrawsCount / DEFAULT_PAGE_SIZE)}
					page={filters.page}
					onChange={handlePaginationClick}
				/>
			</div>
		</>
	)
}

export default observer(Withdraws)
