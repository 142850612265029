import React, { useCallback, useState } from "react"
import { useIntl } from "react-intl"

import AccountService from "services/AccountService"
import commonMessages from "messages/common"
import { TDefaultModalProps } from "hooks/useModal"

import Button from "components/UI/Button"

import styles from "./index.module.scss"

type TProps = TDefaultModalProps & {
	preventClosing: (prevent: boolean) => void
}

const MigrationNotification: React.FC<TProps> = ({ onClose, preventClosing }) => {
	const { formatMessage } = useIntl()
	const [loading, setLoading] = useState<boolean>(false)

	const handleConfirmClick = useCallback(async () => {
		try {
			setLoading(true)
			preventClosing(true)
			await AccountService.setMigratorStatus("migrated_notified")
			preventClosing(false)
			onClose?.()
		} catch (error) {
			console.error("Error setting migrator status:", error)
		} finally {
			preventClosing(false)
			setLoading(false)
		}
	}, [onClose])

	return (
		<div className={styles.migrationNotification}>
			<p>{formatMessage(commonMessages.important_improvements)}</p>
			<p>{formatMessage(commonMessages.trusted_platform)}</p>
			<Button
				label={formatMessage(commonMessages.ok)}
				isLoading={loading}
				onClick={handleConfirmClick}
			/>
		</div>
	)
}

export default MigrationNotification
