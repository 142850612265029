import React from "react"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import cn from "classnames"

import dayjs from "utils/dayjs"
import { EWithdrawalStatuses } from "types/withdrawal"
import { IHistoricalWithdrawal } from "models/Withdrawal"
import useCopyClick from "hooks/useCopyClick"
import commonMessages from "messages/common"

import { TableData, TableRow } from "components/UI/Table"
import ButtonMicro from "components/UI/ButtonMicro"
import { OutlinedBadge } from "components/UI/Badge"
import { badgeColor } from "components/Withdrawal/PreviousWithdrawals/shared"

import styles from "styles/components/FinanceHistory.module.scss"

interface Props {
	withdraw: IHistoricalWithdrawal
	onCancelClick: (e: React.MouseEvent<HTMLElement>) => void
}

const WithdrawRow: React.FC<Props> = ({ withdraw, onCancelClick }) => {
	const { formatMessage, formatNumber } = useIntl()
	const copyClick = useCopyClick()
	const txid = withdraw.withdraw_id

	const handleCopyTxID = () => txid && copyClick(txid)

	const bColor = badgeColor(withdraw.status as EWithdrawalStatuses)

	return (
		<TableRow>
			<TableData width="85px">
				{dayjs(withdraw.created_at).format("DD/MM/YYYY")}&nbsp;
				<span className={styles.table_data_grey_colored}>
					{dayjs(withdraw.created_at).format("HH:mm")}
				</span>
			</TableData>
			<TableData translate="no" width="80px">
				{withdraw.chain}
			</TableData>
			<TableData align="right" width="100px">
				{formatNumber(+withdraw.amount, {
					useGrouping: false,
					maximumFractionDigits: 8,
				})}
				&nbsp;{withdraw.currency}
			</TableData>
			<TableData width="100px">
				{formatNumber(+withdraw.fee_amount, { maximumFractionDigits: 4 })}&nbsp;
				{withdraw.fee_currency}
			</TableData>
			<TableData minWidth="300px" className={cn(styles.table_data_tx, styles.column)}>
				<div className={styles.table_data_tx}>
					{/*{withdraw.txid_url ? (*/}
					{/*	<a href={withdraw.txid_url} target="_blank" rel="noopener noreferrer">*/}
					{/*		{txid || "--"}*/}
					{/*	</a>*/}
					{/*) : (*/}
					{txid || "--"}
					{/*)}*/}
					{txid ? (
						<ButtonMicro onClick={handleCopyTxID} primary>
							<i className="ai ai-copy_new" />
						</ButtonMicro>
					) : null}
				</div>
				{/*{withdraw.attributes_labeled &&*/}
				{/*	withdraw.attributes_labeled.map((attr: IWithdrawAttributes) => (*/}
				{/*		<p key={attr.label} className={styles.table_data_attribues}>*/}
				{/*			<span className="grey-text">{attr.label}:</span>*/}
				{/*			<span>{attr.value}</span>*/}
				{/*		</p>*/}
				{/*	))}*/}
			</TableData>
			<TableData width="120px" align="center">
				<div className={styles.table_data_status}>
					<OutlinedBadge color={bColor}>
						{withdraw.status.replace(/([A-Z])/g, " $1").trim()}
					</OutlinedBadge>
					{/*{withdraw.comment && (*/}
					{/*	<div className={styles.confirmation_container}>*/}
					{/*		{formatMessage(messages.withdraw_comment)}&nbsp;{withdraw.comment}*/}
					{/*	</div>*/}
					{/*)}*/}
				</div>
			</TableData>
			{/*<TableData width="80px" maxWidth="80px">*/}
			{/*	{withdraw.status === EWithdrawalStatuses.NEW && (*/}
			{/*		<ButtonMicro data-id={withdraw.id} onClick={onCancelClick}>*/}
			{/*			<i className="ai ai-cancel_filled" />*/}
			{/*		</ButtonMicro>*/}
			{/*	)}*/}
			{/*</TableData>*/}
		</TableRow>
	)
}

export default WithdrawRow
