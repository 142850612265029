/* eslint-disable no-unused-expressions,no-void */
import React, { createElement, useCallback, useEffect, useRef } from "react"
import ReactSelect, { ControlProps, OptionProps, SelectInstance } from "react-select"
import { OnChangeValue } from "react-select/dist/declarations/src/types"
import { useIntl } from "react-intl"
import cn from "classnames"

import commonMessages from "messages/common"

import { TSelectChangeEvent, TSelectOption } from "./types"
import styles from "./newSelect.module.scss"

function Control<Value>({
	selectProps,
	hasValue,
	getValue,
	menuIsOpen,
	className,
}: ControlProps<TSelectOption<Value>> & { className?: string }) {
	const handleClick = () => (menuIsOpen ? selectProps.onMenuClose() : selectProps.onMenuOpen())

	const option = hasValue && getValue()[0]

	return (
		<button
			className={cn(styles.control, className)}
			type="button"
			onClick={handleClick}
			onBlur={selectProps.onMenuClose}
		>
			{option ? (
				<div className={styles.value}>
					{!!option.icon && typeof option.icon === "string" ? (
						<i className={cn("ai", option.icon)} />
					) : (
						option.icon
					)}
					<span>{option.label}</span>
				</div>
			) : (
				<span className={styles.placeholder}>{selectProps.placeholder}</span>
			)}
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.6665 10.0003L7.99984 13.3337L11.3332 10.0003M4.6665 6.00033L7.99984 2.66699L11.3332 6.00033"
					stroke="white"
					strokeWidth="1.25"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</button>
	)
}

function Option<Value>({ data, selectOption }: OptionProps<TSelectOption<Value>>) {
	const { icon, label, value, disabled } = data

	const handleClick = () => selectOption(data)

	return (
		<button type="button" className={styles.option} disabled={disabled} onClick={handleClick}>
			{!!icon && typeof icon === "string" ? <i className={cn("ai", icon)} /> : icon}
			<span>{data.label}</span>
		</button>
	)
}

type TProps<Value> = {
	name?: string
	placeholder?: string
	label?: string
	error?: string
	options?: TSelectOption<Value>[]
	value?: Value
	defValue?: Value
	loading?: boolean
	onChange?: TSelectChangeEvent<Value>
	className?: string
	controlClassName?: string
}

export function NewSelect<Value = string>({
	name,
	placeholder,
	label,
	error,
	options = [],
	value,
	defValue,
	loading,
	onChange,
	className,
	controlClassName,
}: TProps<Value>) {
	const rootRef = useRef<HTMLDivElement>(null)
	const selectRef = useRef<SelectInstance<TSelectOption<Value>>>(null)
	const inputRef = useRef<HTMLInputElement>()
	const formRef = useRef<HTMLFormElement>()

	const resetRef = useRef<boolean>(false)

	const { formatMessage } = useIntl()

	const handleChange = (option: OnChangeValue<TSelectOption<Value>, any>) => {
		const value = (option as TSelectOption<Value>)?.value
		setTimeout(() => onChange?.(value || null), 0)
		if (resetRef.current) {
			resetRef.current = false
			return
		}
		const nodeInput = inputRef.current
		if (!nodeInput) return
		nodeInput.style.setProperty("display", "none")
		nodeInput.type = "text"
		// eslint-disable-next-line no-proto
		const setValue = Object.getOwnPropertyDescriptor((nodeInput as any).__proto__, "value")?.set
		setValue?.call(nodeInput, value || "")
		nodeInput.dispatchEvent(new Event("input", { bubbles: true }))
		nodeInput.dispatchEvent(new Event("change", { bubbles: true }))
		nodeInput.type = "hidden"
	}

	const clearValue = useCallback(() => {
		resetRef.current = true
		selectRef.current?.clearValue()
	}, [])

	const setValue = (newValue?: Value) => {
		const option = newValue && options.find(option => option.value === value)
		option ? selectRef.current?.selectOption(option) : clearValue()
	}

	useEffect(() => setValue(value), [value])
	useEffect(() => void (value === undefined && setValue(defValue)), [defValue])

	useEffect(() => {
		inputRef.current = rootRef.current?.querySelector(`input[name="${name}"]`) as HTMLInputElement
	}, [name])

	useEffect(() => {
		const nodeForm = rootRef.current?.closest("form") || undefined
		formRef.current = nodeForm

		if (!nodeForm) return

		nodeForm.addEventListener("reset", clearValue)
		// eslint-disable-next-line consistent-return
		return () => nodeForm.removeEventListener("reset", clearValue)
	}, [])

	return (
		<div ref={rootRef} className={cn(styles.select, className)}>
			{!!label?.length && <div className={styles.label}>{label}</div>}

			<ReactSelect
				ref={selectRef}
				name={name}
				isLoading={loading}
				placeholder={placeholder || formatMessage(commonMessages.select)}
				classNamePrefix="react-select"
				options={options}
				isSearchable={false}
				menuPlacement="auto"
				components={{
					Option: Option<Value>,
					Control: props =>
						createElement(Control<Value>, {
							...props,
							className: controlClassName,
						} as ControlProps<TSelectOption<Value>>),
				}}
				onChange={handleChange}
			/>

			{!!error?.length && <p className={styles.error}>{error}</p>}
		</div>
	)
}
