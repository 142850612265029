import React, { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import dayjs from "utils/dayjs"
import cn from "classnames"

import { EWithdrawalStatuses } from "types/withdrawal"
import { IHistoricalWithdrawal } from "models/Withdrawal"
import useCopyClick from "hooks/useCopyClick"
import historyMessages from "messages/history"
import financeMessages from "messages/finance"

import ButtonMicro from "components/UI/ButtonMicro"
import { OutlinedBadge } from "components/UI/Badge"

import styles from "styles/components/DepositWithdrawal.module.scss"

import { badgeColor } from "./shared"

interface Props {
	withdraw: IHistoricalWithdrawal
	onCancel: (amount: string, currencyCode: string, slug: string) => void
	locale: string
}

const PreviousWithdrawalRowMobile: React.FC<Props> = ({ withdraw, onCancel, locale }) => {
	const { formatMessage, formatNumber } = useIntl()
	// const localeNavigate = useLocaleNavigate();
	const copyClick = useCopyClick()
	const statusCaption = withdraw.status.replace(/([A-Z])/g, ` $1`).toLowerCase()
	const [isExpanded, setIsExpanded] = useState<boolean>(false)
	const txid = withdraw.withdraw_id

	const handleExpandClick = useCallback(() => {
		setIsExpanded(prevState => !prevState)
	}, [])

	// const handleContinueClick = useCallback(() => {
	// 	localeNavigate(routes.confirm.getWithdrawConfirm(withdraw.slug || ""));
	// }, [locale, withdraw]);

	// const handleCancelClick = useCallback(() => {
	// 	onCancel(withdraw.amount.toString(), withdraw.currency?.code ?? "", withdraw.slug!);
	// }, [withdraw, onCancel]);

	const handleCopyToClipboard = () => {
		if (!txid) return
		copyClick(
			txid,
			formatMessage(financeMessages.copied_to_clipboard, {
				label: "TX",
			}),
		)
	}

	const ExpandedContent = () => (
		<div className={styles.expanded_content_container}>
			{txid ? (
				<div className={styles.card_mobile_additional_info}>
					<div className={styles.order_data_item_title}>Tx:</div>&nbsp;
					<div className={styles.card_mobile_txid}>
						{/*{withdraw.txid_url ? (*/}
						{/*	<a href={withdraw.txid_url} target="_blank" rel="noopener noreferrer">*/}
						{/*		{withdraw.txid}*/}
						{/*	</a>*/}
						{/*) : (*/}
						{txid}
						{/*)}*/}
						<ButtonMicro onClick={handleCopyToClipboard} primary>
							<i className="ai ai-copy_new" />
						</ButtonMicro>
					</div>
				</div>
			) : null}
			{/*{withdraw.note ? (*/}
			{/*	<div className={cn(styles.card_mobile_additional_info, styles.colored)}>*/}
			{/*		<i className="ai ai-edit" />*/}
			{/*		<span>{withdraw.note}</span>*/}
			{/*	</div>*/}
			{/*) : null}*/}
			{withdraw.status === EWithdrawalStatuses.PENDING && (
				<div className={styles.card_mobile_additional_info}>
					<i className="ai ai-clock" />
					<span>{formatMessage(financeMessages.withdrawal_was_sent_to_moderation)}</span>
				</div>
			)}
			{/*{status === "active" && (*/}
			{/*	<Button*/}
			{/*		iconCode="mini_up_right"*/}
			{/*		iconAlign="left"*/}
			{/*		color="primary"*/}
			{/*		onClick={handleContinueClick}*/}
			{/*		label={formatMessage(commonMessages.continue)}*/}
			{/*		className={styles.continue_button}*/}
			{/*	/>*/}
			{/*)}*/}
		</div>
	)

	return (
		<div className={cn(styles.card_mobile, isExpanded && styles.expanded)}>
			<div className={styles.card_mobile_header}>
				<div className={styles.card_mobile_currency}>{withdraw.currency}</div>
				<div className={styles.card_mobile_date_time}>
					<span className={styles.card_mobile_date_time_item}>
						{dayjs(withdraw.created_at).format("DD/MM/YYYY")}
					</span>
					<span className={styles.card_mobile_date_time_item}>
						{dayjs(withdraw.created_at).format("HH:mm")}
					</span>
				</div>
				<OutlinedBadge color={badgeColor(withdraw.status as EWithdrawalStatuses)}>
					{statusCaption}
				</OutlinedBadge>
				{/*{status === "active" && withdraw.slug && (*/}
				{/*	<div className={styles.card_mobile_action}>*/}
				{/*		<ButtonMicro data-id={withdraw.id} onClick={handleCancelClick}>*/}
				{/*			<i className="ai ai-trash_filled" />*/}
				{/*		</ButtonMicro>*/}
				{/*	</div>*/}
				{/*)}*/}
				<div className={cn(styles.card_mobile_action, isExpanded && styles.active)}>
					<ButtonMicro onClick={handleExpandClick}>
						<i className="ai ai-arrow_down" />
					</ButtonMicro>
				</div>
			</div>
			<div className={styles.card_mobile_content}>
				<i className={`ai ai-${withdraw.currency?.toLowerCase()}`} />
				<div className={styles.order_data_item}>
					<div className={styles.order_data_item_title}>
						{formatMessage(historyMessages.deposits_table_channel)}
					</div>
					&nbsp;
					{withdraw.chain}
				</div>
				<div className={styles.order_data_item}>
					<div className={styles.order_data_item_title}>
						{formatMessage(historyMessages.orders_table_amount)}
					</div>
					&nbsp;
					<span className={styles.primary_bold}>
						{formatNumber(+withdraw.amount, { maximumFractionDigits: 8 })}
						&nbsp;{withdraw.currency}
					</span>
				</div>
				<div className={styles.order_data_item}>
					<div className={styles.order_data_item_title}>
						{formatMessage(historyMessages.trades_table_fee)}
					</div>
					&nbsp;
					{withdraw.fee_amount}&nbsp;{withdraw.fee_currency}
				</div>
				{/*{withdraw.attributes_labeled*/}
				{/*	? withdraw.attributes_labeled.map((attr) => (*/}
				{/*			<div className={styles.order_data_item} key={attr.label}>*/}
				{/*				<div className={styles.order_data_item_title}>{attr.label}</div>:&nbsp;*/}
				{/*				<span style={{ wordBreak: "break-all" }}>{attr.value}</span>*/}
				{/*			</div>*/}
				{/*	  ))*/}
				{/*	: null}*/}
			</div>
			{isExpanded && <ExpandedContent />}
		</div>
	)
}

export default PreviousWithdrawalRowMobile
