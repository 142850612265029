import { queryVars } from "constants/query"
import { IHistoricalWithdrawal } from "models/Withdrawal"

export enum EWithdrawLoading {
	CURRENCIES = "currencies",
	METHODS = "methods",
	CREATING = "creating",
	DETAILS = "details",
	CONFIRMATION = "confirmation",
	HISTORY = "history",
}

export interface IGetWithdrawMethodsParams {
	currency: string
}

export type TGetWithdrawMethodsResponse = {
	id: string
	name: string
	is_withdraw_enabled: boolean
	min_verification_level: number
	min_withdraw: string
	withdraw_fee_amount: string
	withdraw_fee_rate: string
}[]

export interface ICreateWithdrawRequestBody {
	withdraw_method?: string
	address: string
	amount: string
	tag?: string
}

export interface ICreateWithdrawResponse {
	slug: string
	is_totp_required: boolean
	// "totp_timeout": null,
	is_totp_confirmed: boolean
	is_pincode_required: boolean
	pincode_tries_left: number
	pincode_timeout: string
	pincode_generated_at: string
	is_pincode_confirmed: boolean
	deadline_at: string
	is_totp_ok: boolean
	is_pincode_ok: boolean
	is_ok: boolean
	withdraw_method?: string
	amount: string
	address: string
	tag: string
	attributes_labeled: {
		label: string
		value: string
	}[]
	payment_method_name: string
	// "withdraw": null,
	currency: string
	fee_amount: string
}

export interface IDetailsWithdrawResponse {
	slug: string
	is_totp_required: boolean
	totp_timeout: string | null
	is_totp_confirmed: boolean
	is_pincode_required: boolean
	pincode_tries_left: number
	pincode_timeout: string
	pincode_generated_at: string
	is_pincode_confirmed: boolean
	deadline_at: string
	is_totp_ok: boolean
	is_pincode_ok: boolean
	is_ok: boolean
	currency: {
		sode?: string
		name?: string
	}
	fee_amount: number
	payment_method_name: string
	// withdraw: null
	address: string
	tag: string | null
	attributes_labeled: {
		label: string
		value: string
	}[]
	amount: string
}

export interface IHistoryWithdrawParams {
	[queryVars.page]?: number
	[queryVars.page_size]?: number
	[queryVars.currency]?: string
}

export interface IHistoryWithdrawResponse {
	results: IHistoricalWithdrawal[]
	count: number
}

export enum WithdrawalStatusesEnum {
	WITHDRAW_STATUS_NEW = 10, // created new, need to be confirmed
	WITHDRAW_STATUS_CONFIRMED = 20, // confirmed by user, need to be verified
	WITHDRAW_STATUS_VERIFIED = 23, // verified by automatic system. ve additinal info in verification field
	WITHDRAW_STATUS_PROCESSING = 27, // processing by bot or operator
	WITHDRAW_STATUS_DONE = 30, // money sent, see tx id in payment field
	WITHDRAW_STATUS_REFUSED = 40, // refuxed by operator or bot
	WITHDRAW_STATUS_CANCELLED = 50, // cancelled by user
}

export enum EWithdrawalStatuses {
	NEW = "New",
	PENDING = "Pending",
	SUCCESS = "Success",
	CANCEL_BY_USER = "CancelByUser",
	REJECT = "Reject",
	FAIL = "Fail",
	BLOCKCHAIN_CONFIRMED = "BlockchainConfirmed",
	MORE_INFORMATION_REQUIRED = "MoreInformationRequired",
	UNKNOWN = "Unknown",
}

export interface IGetWithdrawLimitParams {
	currency: string
}
