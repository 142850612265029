import React, { createElement, useCallback, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { createRoot, Root } from "react-dom/client"

import { Provider, rootStore } from "models/Root"
import LanguageProvider from "providers/LanguageProvider"

import styles from "styles/components/ModalHook.module.scss"

export type TDefaultModalProps = {
	onClose?: () => void
}

function useModal<Props extends TDefaultModalProps = TDefaultModalProps>(Modal: React.FC<Props>) {
	const [isOpened, setOpened] = useState<boolean>(false)

	const propsRef = useRef<Props>()

	const closableRef = useRef<boolean>(true)
	const preventClosing = useCallback((prevent: boolean) => {
		closableRef.current = !prevent
	}, [])

	const open = useCallback((props: Omit<Props, "onClose"> = {} as Omit<Props, "onClose">) => {
		propsRef.current = { ...props, onClose: close } as Props
		setOpened(true)
	}, [])
	const close = useCallback(() => closableRef.current && setOpened(false), [])

	const reactRootRef = useRef<Root>()
	const wrapperRef = useRef<HTMLDivElement>()

	useEffect(() => {
		const handleKeyDown = ({ key }: KeyboardEvent) => key === "Escape" && close()

		if (isOpened) {
			const wrapper = document.createElement("div")
			wrapperRef.current = wrapper

			wrapper.classList.add(styles.modalWrapper)
			wrapper.onclick = ({ target }) => target === wrapper && close()

			document.body.appendChild(wrapper)

			reactRootRef.current = createRoot(wrapper)
			reactRootRef.current.render(
				<Provider value={rootStore}>
					<LanguageProvider>{createElement(observer(Modal), propsRef.current)}</LanguageProvider>
				</Provider>,
			)

			setTimeout(() => wrapper.classList.add(styles.opened), 0)

			window.addEventListener("keydown", handleKeyDown)
		} else if (wrapperRef.current) {
			const wrapper = wrapperRef.current as HTMLDivElement

			wrapper.classList.remove(styles.opened)

			wrapper.ontransitionend = ({ target }) => {
				if (target !== wrapper) return
				reactRootRef.current?.unmount()
				wrapper.parentNode?.removeChild(wrapper)
				wrapperRef.current = undefined
				reactRootRef.current = undefined
			}
		}

		return () => window.removeEventListener("keydown", handleKeyDown)
	}, [isOpened])

	return [open, close, preventClosing] as [typeof open, typeof close, typeof preventClosing]
}

export default useModal
