import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import Helmet from "react-helmet"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import messages from "messages/history"
import commonMessages from "messages/common"
import CurrencySelect, { IOption } from "components/UI/CurrencySelect"
import LoadingSpinner from "components/UI/LoadingSpinner"
import Pagination from "components/UI/Pagination"
import { useMst } from "models/Root"
import NoRowsMessage from "components/Table/NoRowsMessage"
import useWindowSize from "hooks/useWindowSize"
import styles from "styles/components/FinanceHistory.module.scss"
import { Table } from "components/UI/Table"
import config from "helpers/config"
import { IHeaderColumn } from "components/UI/Table/Table"
import Button from "components/UI/Button"
import DateRangePicker, { IChangeDateRange, IDateRange } from "components/UI/DateRangePicker"
import pageStyles from "styles/pages/Page.module.scss"
import DepositRow from "./DepositRow"
import MobileDepositRow from "./DepositMobileRow"
import DepositRowSkeleton from "./DepositRowSkeleton"
import withdrawMessages from "../../messages/history"

const PAGE_SIZE = 15

const Deposits: React.FC = () => {
	const {
		deposit: {
			depositHistory,
			currencies,
			loadCurrencies,
			loadDepositHistory,
			isLoading,
			depositsCount,
		},
	} = useMst()
	const navigate = useNavigate()
	const { desktop } = useWindowSize()
	const { formatMessage } = useIntl()

	const datePickerRef = React.useRef<HTMLDivElement>(null)

	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState<{ date: IDateRange }>({
		date: {
			startDate: null,
			endDate: null,
			key: "selection",
		},
	})
	const [selectedDates, setSelectedDates] = useState<{ startDate: string; endDate: string }>({
		startDate: "",
		endDate: "",
	})

	const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined)

	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

	const currenciesOptions: IOption[] =
		currencies?.map(
			(currency): IOption => ({
				label: {
					code: currency.code,
					name: currency.name,
					image_png: currency.image_png ?? "",
					image_svg: currency.image_svg ?? "",
				},
				value: currency.code,
			}),
		) || []

	useEffect(() => {
		loadCurrencies()
		loadDepositHistory(selectedCurrency)
	}, [loadCurrencies, loadDepositHistory, selectedCurrency])

	const handlePaginationClick = useCallback(
		(newPage: number) => {
			if (newPage === page) return

			setPage(newPage)
			navigate({ search: `?page=${newPage}&page-size=${PAGE_SIZE}` })
			loadDepositHistory()
		},
		[page, loadDepositHistory, navigate],
	)

	const handleCurrencySelect = useCallback(
		(e: IOption | null) => {
			const currency = e?.value || undefined
			setSelectedCurrency(currency)
			navigate({ search: `?currency=${currency}&page=1&page-size=${PAGE_SIZE}` })
			loadDepositHistory(currency)
		},
		[loadDepositHistory, navigate],
	)

	const handleDateRangeChange = (nextDate: IChangeDateRange): void => {
		const startDate = nextDate.selection.startDate
			? new Date(nextDate.selection.startDate).toLocaleDateString("en-US")
			: ""
		const endDate = nextDate.selection.endDate
			? new Date(nextDate.selection.endDate).toLocaleDateString("en-US")
			: ""

		setFilter(prevState => ({
			...prevState,
			date: {
				startDate: nextDate.selection.startDate,
				endDate: nextDate.selection.endDate,
				key: "selection",
			},
		}))

		setSelectedDates({ startDate, endDate })
	}

	const handleDateRangeClose = (): void => {
		setIsDatePickerOpen(false)

		if (selectedDates.startDate && selectedDates.endDate) {
			const { startDate, endDate } = selectedDates
			const queryString = `?start_time=${startDate}&end_time=${endDate}&page=1&page-size=${PAGE_SIZE}`
			navigate({ search: queryString })
			loadDepositHistory(selectedCurrency, startDate, endDate)
		}
	}

	const handleRangeClear = (): void => {
		setFilter({
			date: {
				startDate: null,
				endDate: null,
				key: "selection",
			},
		})

		navigate({ search: `?page=1&page-size=${PAGE_SIZE}` })
		setSelectedDates({ startDate: "", endDate: "" })
		loadDepositHistory(selectedCurrency)
	}

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
				handleDateRangeClose()
			}
		}

		if (isDatePickerOpen) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			document.removeEventListener("mousedown", handleClickOutside)
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [isDatePickerOpen, selectedDates, navigate, loadDepositHistory, selectedCurrency])

	const handleSearch = (): void => {
		if (selectedDates.startDate && selectedDates.endDate) {
			const { startDate, endDate } = selectedDates
			const queryString = `?start_time=${startDate}&end_time=${endDate}&page=1&page-size=${PAGE_SIZE}`
			navigate({ search: queryString })

			loadDepositHistory(selectedCurrency, startDate, endDate)
		} else {
			navigate({ search: `?page=1&page-size=${PAGE_SIZE}` })
			loadDepositHistory(selectedCurrency)
		}
	}

	const columns: IHeaderColumn[] = [
		{
			name: "date",
			label: formatMessage(messages.orders_table_date),
			width: "85px",
		},
		{
			label: formatMessage(commonMessages.coin),
			name: "coin",
			width: "80px",
		},
		{
			label: formatMessage(withdrawMessages.orders_table_amount),
			name: "amount",
		},
		{
			width: "100px",
		},
		{
			label: "TX",
			name: "tx",
			width: "100px",
		},
		{
			name: "status",
			label: formatMessage(messages.orders_table_status),
			align: "center",
			width: "300px",
			minWidth: "300px",
		},
	]

	return (
		<>
			<Helmet title={`${formatMessage(messages.deposits_history)} | ${config.department}`} />
			<div className={pageStyles.filters}>
				<div ref={datePickerRef}>
					<DateRangePicker
						ranges={[filter.date]}
						onChange={handleDateRangeChange}
						onRangeClear={handleRangeClear}
						containerClassName={pageStyles.date_picker}
						staticRanges={[]}
						inputRanges={[]}
					/>
				</div>
				<div className={pageStyles.filter_select}>
					<CurrencySelect
						onSelectChange={handleCurrencySelect}
						options={currenciesOptions}
						value={currenciesOptions.find(option => option.value === selectedCurrency)}
						placeholder={formatMessage(messages.deposits_table_currency)}
						autoFocus
						withoutLabel
						mini
						isClearable
					/>
				</div>
				<div className={pageStyles.filters_buttons}>
					<Button
						variant="filled"
						color="primary"
						label={formatMessage(commonMessages.search)}
						mini
						onClick={handleSearch}
						isLoading={isLoading}
					/>
				</div>
			</div>
			{desktop ? (
				<Table stripped header={{ columns }}>
					{isLoading ? (
						[...new Array(PAGE_SIZE)].map((_, i) => <DepositRowSkeleton key={i} />)
					) : depositHistory.results.length > 0 ? (
						depositHistory.results.map(deposit => (
							<DepositRow deposit={deposit} key={deposit.txid} />
						))
					) : (
						<NoRowsMessage disableHover />
					)}
				</Table>
			) : (
				<div className={styles.mobile_container}>
					{isLoading ? (
						<LoadingSpinner />
					) : depositHistory.results.length > 0 ? (
						depositHistory.results.map(deposit => (
							<MobileDepositRow deposit={deposit} key={deposit.txid} />
						))
					) : (
						<NoRowsMessage disableHover />
					)}
				</div>
			)}
			<div className={styles.pagination_container}>
				<Pagination
					count={Math.ceil(depositsCount / PAGE_SIZE)}
					page={page}
					onChange={handlePaginationClick}
				/>
			</div>
		</>
	)
}

export default observer(Deposits)
