import React, { useEffect, useId, useState } from "react"
import cn from "classnames"
import { useIntl } from "react-intl"

import { EMarginMode } from "types/account"
import { useMst } from "models/Root"
import useModal, { TDefaultModalProps } from "hooks/useModal"
import financeMessages from "messages/finance"

import Button from "components/UI/Button"
import styles from "styles/components/Modals/MarginMode.module.scss"

type TMagrinMode = {
	value: EMarginMode
	name: string
	level: string
	requirements: string[]
	supports?: string[]
	modes?: string[]
	futures?: string[]
	soon?: boolean
}

interface TSectionProps {
	keyPrefix: string
	messageId: keyof typeof financeMessages
	items?: string[]
	ordered?: boolean
	checked?: boolean
}

const Section: React.FC<TSectionProps> = ({ keyPrefix, messageId, items, ordered, checked }) => {
	const { formatMessage } = useIntl()

	if (!items) return null
	return (
		<div className={styles.section}>
			<span>{formatMessage(financeMessages[messageId])}:</span>
			<ul>
				{items.map((line, index) => (
					<li key={`${keyPrefix}-${messageId}-item-${index.toString()}`}>
						{checked && (
							<svg
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M17.3331 5L8.16642 14.1667L3.99976 10"
									stroke="#20B26C"
									strokeWidth="1.25"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
						{ordered && <div>{index + 1}.</div>}
						<span>{line}</span>
					</li>
				))}
			</ul>
		</div>
	)
}

const MarginMode: React.FC<TDefaultModalProps> = ({ onClose }) => {
	const { formatMessage } = useIntl()
	const {
		account: { marginMode, updateMarginMode },
	} = useMst()
	const id = useId()
	const [loading, setLoading] = useState<EMarginMode | false>(false)

	const getSelectClickHandler = (mode: EMarginMode) => async () => {
		try {
			setLoading(mode)
			await updateMarginMode(mode)
		} finally {
			setLoading(false)
		}
	}

	const modes: TMagrinMode[] = [
		{
			value: EMarginMode.ISOLATED,
			name: "Isolated Margin",
			level: formatMessage(financeMessages.regular_user),
			requirements: [
				formatMessage(financeMessages.no_options_orders),
				formatMessage(financeMessages.no_active_spot_margin_orders),
				formatMessage(financeMessages.ensure_sufficient_assets),
				formatMessage(financeMessages.no_outstanding_liabilities),
			],
			modes: ["One-Way", "Hedge (USDT-Perp Only)"],
			futures: [
				formatMessage(financeMessages.trade_perpetual_and_futures),
				formatMessage(financeMessages.isolated_margin_mode),
				formatMessage(financeMessages.supports_manual_and_auto_margin),
			],
			soon: true,
		},
		{
			value: EMarginMode.REGULAR,
			name: "Cross Margin",
			level: formatMessage(financeMessages.regular_user),
			requirements: [
				formatMessage(financeMessages.imr_below_100),
				formatMessage(financeMessages.no_opposing_positions),
			],
			supports: [formatMessage(financeMessages.all_perpetual_and_futures)],
			modes: ["One-Way", "Hedge (USDT-Perp Only)"],
			futures: [
				formatMessage(financeMessages.enables_trading_derivatives),
				formatMessage(financeMessages.enables_offsetting),
			],
		},
		{
			value: EMarginMode.PORTFOLIO,
			name: "Portfolio Margin",
			level: formatMessage(financeMessages.professional_derivatives_trader),
			requirements: [
				formatMessage(financeMessages.imr_below_100),
				formatMessage(financeMessages.no_positions_in_hedge_mode),
				formatMessage(financeMessages.equity_above_1000),
			],
			supports: [formatMessage(financeMessages.all_perpetual_and_futures)],
			modes: ["One-Way"],
			futures: [
				formatMessage(financeMessages.offset_collateral_risk),
				formatMessage(financeMessages.offset_spot_pnl),
			],
			soon: true,
		},
	]

	return (
		<div className={styles.marginModeModal}>
			<div className={styles.header}>
				<span>{formatMessage(financeMessages.choose_margin_mode)}</span>
				<button type="button" onClick={onClose} aria-label="Close">
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12 4L4 12M4 4L12 12"
							strokeWidth="1.25"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
			<div className={styles.content}>
				{modes.map(({ value, name, level, requirements, supports, modes, futures, soon }) => {
					const key = `margin-mode-modal-${id}-${value}`
					const isSelected = value === marginMode
					const messageKey = `${value.toLowerCase()}` as keyof typeof financeMessages
					const marginModeLabel = financeMessages[messageKey]
						? formatMessage(financeMessages[messageKey])
						: name

					const action = isSelected ? (
						<span>{formatMessage(financeMessages.selected)}</span>
					) : (
						<Button
							label={
								soon
									? formatMessage(financeMessages.coming_soon)
									: formatMessage(financeMessages.select)
							}
							isLoading={loading === value}
							disabled={soon || (!!loading && loading !== value)}
							onClick={soon ? undefined : getSelectClickHandler(value)}
						/>
					)

					return (
						<div key={key} className={cn(styles.item, { [styles.active]: isSelected })}>
							<div className={styles.title}>
								<div>
									<span>{marginModeLabel}</span>
									<span>{level}</span>
								</div>
								<div className={styles.action}>{action}</div>
							</div>
							<hr />
							<Section keyPrefix={key} messageId="requirements" items={requirements} ordered />
							<hr />
							<Section keyPrefix={key} messageId="supports" items={supports} checked />
							<Section keyPrefix={key} messageId="modes" items={modes} checked />
							<Section keyPrefix={key} messageId="futures" items={futures} checked />
							<div className={styles.footer}>{action}</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const useMarginMode = () => {
	const {
		account: { marginMode, loadMarginMode },
		global: { isAuthenticated },
	} = useMst()
	const { formatMessage } = useIntl()
	const [open, close] = useModal(MarginMode)

	useEffect(() => {
		if (!marginMode && isAuthenticated) loadMarginMode()
		else close()
	}, [marginMode, isAuthenticated])

	const marginModeName = marginMode
		? financeMessages[marginMode.toLowerCase() as keyof typeof financeMessages]
			? formatMessage(financeMessages[marginMode.toLowerCase() as keyof typeof financeMessages])
			: marginMode
		: ""

	return { openMarginModeModal: open, closeMarginModeModal: close, marginMode, marginModeName }
}

export default useMarginMode
