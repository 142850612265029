import React from "react"
import cn from "classnames"

import { SidebarMenuLevelsEnum } from "types/sidebarMenuLevels"
import useWindowSize from "hooks/useWindowSize"

import Cookies from "components/Cookies"
import Sidebar from "components/UI/Sidebar"
import Header from "components/NewUI/Header"

import styles from "styles/layout/ProfileLayout.module.scss"

interface IProps {
	sidebarMenuLevel?: SidebarMenuLevelsEnum
	children?: React.ReactNode
}

const ProfileLayout: React.FC<IProps> = ({ children, sidebarMenuLevel }) => {
	const { desktop } = useWindowSize()
	return (
		<>
			<Header />
			<div className={cn({ [styles.profile_with_sidebar]: desktop })}>
				{desktop && <Sidebar menuLevel={sidebarMenuLevel} />}
				<div className={styles.profile_content}>{children}</div>
			</div>
			<Cookies />
		</>
	)
}

export default ProfileLayout
