import React from "react"
import { useIntl } from "react-intl"

import dayjs from "utils/dayjs"
import { EWithdrawalStatuses } from "types/withdrawal"
import { IHistoricalWithdrawal } from "models/Withdrawal"
import useCopyClick from "hooks/useCopyClick"
import messages from "messages/history"

import ButtonMicro from "components/UI/ButtonMicro"
import { OutlinedBadge } from "components/UI/Badge"
import { badgeColor } from "components/Withdrawal/PreviousWithdrawals/shared"

import styles from "styles/components/FinanceHistory.module.scss"

interface Props {
	withdraw: IHistoricalWithdrawal
	onCancelClick: (e: React.MouseEvent<HTMLElement>) => void
}

const MobileWithdrawRow: React.FC<Props> = ({ withdraw, onCancelClick }) => {
	const isActive = withdraw.status === EWithdrawalStatuses.NEW
	const { formatMessage, formatNumber } = useIntl()
	const txid = withdraw.withdraw_id
	const bColor = badgeColor(withdraw.status as EWithdrawalStatuses)
	const copyClick = useCopyClick()

	const handleCopyTxID = () => txid && copyClick(txid)

	return (
		<div className={styles.inner_container}>
			<div className={styles.outlined_card}>
				<div className={styles.outlined_card_header}>
					<div className={styles.order_direction_wrapper}>
						<OutlinedBadge color={bColor}>
							{withdraw.status.replace(/([A-Z])/g, " $1").trim()}
						</OutlinedBadge>
					</div>
					<div className={styles.order_date_wrapper}>
						{dayjs(withdraw.created_at).format("DD/MM/YYYY")}
					</div>
					<div className={styles.order_time_wrapper}>
						{dayjs(withdraw.created_at).format("HH:mm:ss")}
					</div>
					{/*{isActive && (*/}
					{/*	<div className={styles.order_action_wrapper}>*/}
					{/*		<ButtonMicro data-id={withdraw.id} onClick={onCancelClick}>*/}
					{/*			<i className="ai ai-trash_filled" />*/}
					{/*		</ButtonMicro>*/}
					{/*	</div>*/}
					{/*)}*/}
				</div>
				<div className={styles.order_content}>
					<div className={styles.order_data}>
						<div className={styles.order_data_item}>
							<div className={styles.order_data_item_title}>
								{formatMessage(messages.deposits_table_channel)}
							</div>
							&nbsp;
							{withdraw.chain}
						</div>
						<div className={styles.order_data_item}>
							<div className={styles.order_data_item_title}>
								{formatMessage(messages.orders_table_amount)}
							</div>
							&nbsp;
							{formatNumber(+withdraw.amount, {
								maximumFractionDigits: 8,
							})}
							&nbsp;{withdraw.currency}
						</div>
						<div className={styles.order_data_item}>
							<div className={styles.order_data_item_title}>
								{formatMessage(messages.orders_table_amount)}
							</div>
							&nbsp;
							{formatNumber(+withdraw.fee_amount, { maximumFractionDigits: 4 })}&nbsp;
							{withdraw.fee_currency}
						</div>
						<div className={styles.order_data_item}>
							<div className={styles.order_data_item_title}>
								{formatMessage(messages.deposits_table_transaction)}&nbsp;
							</div>
							<div className={styles.link_container} style={{ flexGrow: 1, textAlign: "right" }}>
								{/*{withdraw.txid_url ? (*/}
								{/*	<a target="_blank" rel="noopener noreferrer" href={withdraw.txid_url}>*/}
								{/*		{withdraw.txid}*/}
								{/*	</a>*/}
								{/*) : (*/}
								{txid || ""}
								{/*)}*/}
								{txid ? (
									<ButtonMicro onClick={handleCopyTxID} primary>
										<i className="ai ai-copy_new" />
									</ButtonMicro>
								) : null}
							</div>
						</div>
						{/*{withdraw.attributes_labeled &&*/}
						{/*	withdraw.attributes_labeled.map((attr) => (*/}
						{/*		<div className={styles.order_data_item} key={attr.label}>*/}
						{/*			<div className={styles.order_data_item_title}>{attr.label}</div>*/}
						{/*			&nbsp;*/}
						{/*			<span className={styles.attribute_value}>{attr.value}</span>*/}
						{/*		</div>*/}
						{/*	))}*/}
					</div>
				</div>
			</div>
		</div>
	)
}

export default MobileWithdrawRow
