import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import Table, { AlignEnum, IHeaderColumn } from "components/UI/Table/Table"
import { formatWallets, generateCharArray, generateNumArray } from "helpers/wallets"
import financeMessages from "messages/finance"
import { useMst } from "models/Root"
import Tooltip from "components/UI/Tooltip"
import styles from "styles/pages/Wallets.module.scss"
import { AccountTypeEnum, ICollateralInfo } from "types/account"
import NoRowsMessage from "components/Table/NoRowsMessage"
import MarginRiskModal from "components/Terminal/modals/MarginRiskModal"
import { MarginModalEnum } from "types/exchange"
import MarginOperationModal from "components/Terminal/modals/MarginOperationModal"
import { queryVars } from "constants/query"
import WalletsTableRowSkeleton from "./WalletsTableRowSkeleton"
import WalletsTableRow from "./WalletsTableRow"
import Tabs from "../Tabs"
import Filters from "../Filters"

const WalletsTable: React.FC = () => {
	const {
		finance: {
			walletsFilter,
			setWalletsFilter,
			isBalancesVisible,
			marginOptions,
			crossMarginOption,
			marginRequiredVerificationLevel,
		},
		account: {
			balancesCross,
			balancesIsolated,
			isBalancesLoaded,
			profileStatus,
			getWalletBalancesByType,
			loadProfileStatus,
			isDepositEnabled,
			isWithdrawEnabled,
			isTransferEnabled,
			loadCollateralInfo,
			collateralInfo,
			isCrossMarginMode,
		},
		tickers: { list: tickers },
		render,
		render: { margin },
	} = useMst()

	const [openedMarginModal, setOpenedMarginModal] = useState<{
		type: MarginModalEnum | ""
		pair: string
		code: string
	}>({ type: "", pair: "", code: "" })

	const [isMarginRiskModalOpened, setIsMarginRiskModalOpened] = useState<boolean>(false)
	const { formatMessage } = useIntl()
	const navigate = useNavigate()
	const tooltipRef = useRef<ReactTooltip>(null)
	const [sortName, sortValue] = walletsFilter.sort.split(".")

	const currentBalances = getWalletBalancesByType(walletsFilter.accountType as AccountTypeEnum)
	const filteredBalances = formatWallets(
		currentBalances,
		walletsFilter,
		crossMarginOption ?? null,
		balancesCross,
		balancesIsolated,
	)

	useEffect(() => {
		if (walletsFilter.accountType === "UNIFIED" && margin) {
			loadCollateralInfo()
		}
	}, [walletsFilter.accountType, loadCollateralInfo, margin])

	const handleChangeFilterSort = (name: string) => {
		const nextFilter = {
			...walletsFilter,
			sort: `${name}.${sortValue === queryVars.asc ? queryVars.desc : queryVars.asc}`,
		}
		setWalletsFilter(nextFilter)
	}

	const handleCloseMarginRiskModal = () => {
		setIsMarginRiskModalOpened(false)
		setWalletsFilter({
			...walletsFilter,
			accountType: AccountTypeEnum.SPOT,
			sort: "code.asc",
		})
		navigate({ [queryVars.search]: `?${queryVars.type}=spot` })
	}

	const handleCloseAcceptedMarginRiskModal = () => {
		setIsMarginRiskModalOpened(false)
		loadProfileStatus()
	}

	const handleCloseModal = () => {
		setOpenedMarginModal({ type: "", code: "", pair: "" })
	}

	useEffect(() => {
		if (walletsFilter.isMargin && profileStatus?.isMarginRulesAcceptRequired) {
			setIsMarginRiskModalOpened(true)
		}
	}, [walletsFilter.isMargin, profileStatus?.isMarginRulesAcceptRequired])

	useEffect(() => {
		ReactTooltip.rebuild()
	}, [
		walletsFilter.accountType,
		openedMarginModal.type,
		isTransferEnabled,
		isDepositEnabled,
		isWithdrawEnabled,
	])

	const handleOpenBorrowModal = () => {
		setOpenedMarginModal(prevState => ({ ...prevState, type: MarginModalEnum.DEPOSIT }))
	}

	const handleMarginTransfer = (code: string, pair = ""): void => {
		setOpenedMarginModal({ type: MarginModalEnum.TRANSFER, pair: pair, code: code })
	}

	const handleMarginBorrow = (code: string, pair = ""): void => {
		setOpenedMarginModal({ type: MarginModalEnum.DEPOSIT, pair: pair, code: code })
	}

	const handleMarginRepay = (code: string, pair = ""): void => {
		setOpenedMarginModal({ type: MarginModalEnum.REPAY, pair: pair, code: code })
	}

	const getCollateralInfo = (currency: string) => {
		if (walletsFilter.accountType === "UNIFIED" && margin) {
			const collateralItem = collateralInfo.find(
				(item: ICollateralInfo) => item.currency === currency,
			)
			return collateralItem
				? {
						collateralSwitch: collateralItem.collateral_switch,
						borrowAmount: collateralItem.borrow_amount,
				  }
				: { collateralSwitch: null, borrowAmount: null }
		}
		return { collateralSwitch: null, borrowAmount: null }
	}

	const columnsSpot: IHeaderColumn[] = [
		{
			label: formatMessage(financeMessages.currency),
			width: "100px",
			name: "code",
		},
		{
			label: formatMessage(financeMessages.available),
			width: "120px",
			name: "available",
			align: "right",
		},
		{
			label: formatMessage(financeMessages.reserve),
			width: "120px",
			name: "reserve",
			align: "right",
		},
		{
			label: formatMessage(financeMessages.total),
			width: "120px",
			name: "balance",
			align: "right",
		},
		{
			label: `${formatMessage(financeMessages.approx_total)} USDT`,
			width: "120px",
			name: "approx-btc",
			align: "right",
		},
		...(walletsFilter.accountType === "UNIFIED" && margin
			? [
					{
						label: formatMessage(financeMessages.borrow_amount),
						width: "120px",
						name: "borrow-amount",
						align: AlignEnum.Right,
					},
			  ]
			: []),
		...(walletsFilter.accountType === "UNIFIED" && margin && isCrossMarginMode
			? [
					{
						label: formatMessage(financeMessages.used_as_collateral),
						width: "160px",
						name: "collateral-switch",
						align: AlignEnum.Right,
					},
			  ]
			: []),
	]

	const columnsCross: IHeaderColumn[] = [
		{
			label: formatMessage(financeMessages.currency),
			width: "100px",
			name: "code",
		},
		{
			label: formatMessage(financeMessages.available),
			width: "120px",
			name: "available",
			align: "right",
		},
		{
			label: formatMessage(financeMessages.reserve),
			width: "120px",
			name: "reserve",
			align: "right",
		},
		{
			label: formatMessage(financeMessages.debt),
			width: "120px",
			name: "debt",
			align: "right",
		},
		{
			label: (
				<>
					{formatMessage(financeMessages.position)}
					<Tooltip id="position" hint text="Position = “Total Amount - Debt”" />
				</>
			),
			width: "120px",
			name: "position",
			align: "right",
		},
		...(walletsFilter.accountType === "UNIFIED" && margin
			? [
					{
						label: formatMessage(financeMessages.borrow_amount),
						width: "120px",
						name: "borrow-amount",
						align: AlignEnum.Right,
					},
			  ]
			: []),
		...(walletsFilter.accountType === "UNIFIED" && margin && isCrossMarginMode
			? [
					{
						label: formatMessage(financeMessages.used_as_collateral),
						width: "160px",
						name: "collateral-switch",
						align: AlignEnum.Right,
					},
			  ]
			: []),
	]

	const columns = walletsFilter.isMarginIsolated
		? columnsCross
		: walletsFilter.isMarginCross
		? columnsCross
		: columnsSpot

	const emptyColumns: IHeaderColumn[] = [
		{
			align: "center",
		},
		{
			align: "center",
			width: "100px",
		},
		{
			align: "center",
			width: "100px",
		},
	]

	return (
		<div className={styles.table_container}>
			<Tabs />
			<Filters />
			<Table
				stripped
				header={{
					primary: true,
					className: styles.table_header,
					columns: columns
						.map(col => ({
							label: col.label,
							align: col.align,
							name: col.name,
							width: col.width,
							minWidth: col.minWidth,
							sort: sortName === col.name ? sortValue : "none",
							onSortChange: handleChangeFilterSort,
						}))
						.concat(emptyColumns as any),
				}}
			>
				{!isBalancesLoaded ? (
					[...new Array(15)].map((_, i: number) => (
						<WalletsTableRowSkeleton key={i} type={walletsFilter.accountType as AccountTypeEnum} />
					))
				) : currentBalances.length > 0 ? (
					walletsFilter.showFiltered ? (
						filteredBalances.length ? (
							filteredBalances.map(b => (
								<WalletsTableRow
									key={b.code}
									type={walletsFilter.accountType as AccountTypeEnum}
									isBalancesVisible={isBalancesVisible}
									isDepositEnabled={isDepositEnabled}
									isWithdrawEnabled={isWithdrawEnabled}
									filterFavorite={walletsFilter.favorites}
									balance={b}
									onMarginTransfer={handleMarginTransfer}
									onMarginRepay={handleMarginRepay}
									tickers={tickers}
									balancesCross={balancesCross}
									balancesIsolated={balancesIsolated}
									marginOptions={marginOptions}
									collateralSwitch={getCollateralInfo(b.code).collateralSwitch}
									borrowAmount={getCollateralInfo(b.code).borrowAmount}
								/>
							))
						) : (
							<NoRowsMessage />
						)
					) : (
						<>
							{currentBalances
								.filter(b => +b.balance > 0)
								.map(b => (
									<WalletsTableRow
										key={b.code}
										type={walletsFilter.accountType as AccountTypeEnum}
										isBalancesVisible={isBalancesVisible}
										isDepositEnabled={profileStatus?.is_deposit_enabled}
										isWithdrawEnabled={profileStatus?.is_withdraw_enabled}
										filterFavorite={walletsFilter.favorites}
										balance={b}
										onMarginTransfer={handleMarginTransfer}
										onMarginRepay={handleMarginRepay}
										tickers={tickers}
										balancesCross={balancesCross}
										balancesIsolated={balancesIsolated}
										collateralSwitch={getCollateralInfo(b.code).collateralSwitch}
										borrowAmount={getCollateralInfo(b.code).borrowAmount}
									/>
								))}
							<div className={styles.table_divider} />
							{generateCharArray("a", "z").map((ch: string) =>
								currentBalances.filter(b => b.code.startsWith(ch.toUpperCase()) && +b.balance === 0)
									.length > 0 ? (
									<React.Fragment key={ch}>
										<span className={styles.table_letter}>{ch}</span>
										{currentBalances
											.filter(b => b.code.startsWith(ch.toUpperCase()) && +b.balance === 0)
											.map(b => (
												<WalletsTableRow
													key={b.code}
													type={walletsFilter.accountType as AccountTypeEnum}
													isBalancesVisible={isBalancesVisible}
													isDepositEnabled={isDepositEnabled}
													isWithdrawEnabled={isWithdrawEnabled}
													filterFavorite={walletsFilter.favorites}
													balance={b}
													onMarginTransfer={handleMarginTransfer}
													onMarginRepay={handleMarginRepay}
													tickers={tickers}
													balancesCross={balancesCross}
													balancesIsolated={balancesIsolated}
													collateralSwitch={getCollateralInfo(b.code).collateralSwitch}
													borrowAmount={getCollateralInfo(b.code).borrowAmount}
												/>
											))}
									</React.Fragment>
								) : null,
							)}
							{generateNumArray().map((n: number) =>
								currentBalances.filter(b => b.code.startsWith(n.toString()) && +b.balance === 0)
									.length > 0 ? (
									<React.Fragment key={n}>
										<span className={styles.table_letter}>{n}</span>
										{currentBalances
											.filter(b => b.code.startsWith(n.toString()) && +b.balance === 0)
											.map(b => (
												<WalletsTableRow
													key={b.code}
													type={walletsFilter.accountType as AccountTypeEnum}
													isBalancesVisible={isBalancesVisible}
													isDepositEnabled={isDepositEnabled}
													isWithdrawEnabled={isWithdrawEnabled}
													filterFavorite={walletsFilter.favorites}
													balance={b}
													onMarginTransfer={handleMarginTransfer}
													onMarginRepay={handleMarginRepay}
													tickers={tickers}
													balancesCross={balancesCross}
													balancesIsolated={balancesIsolated}
													collateralSwitch={getCollateralInfo(b.code).collateralSwitch}
													borrowAmount={getCollateralInfo(b.code).borrowAmount}
												/>
											))}
									</React.Fragment>
								) : null,
							)}
						</>
					)
				) : (
					<NoRowsMessage />
				)}
			</Table>
			<MarginOperationModal
				modal={openedMarginModal.type}
				onClose={handleCloseModal}
				onBorrowModalOpen={handleOpenBorrowModal}
				pair={openedMarginModal.pair}
				code={openedMarginModal.code}
			/>
			{!openedMarginModal.type && (
				<>
					<Tooltip
						id="margin-transfer"
						place="bottom"
						text={formatMessage(financeMessages.margin_transfer_tooltip)}
					/>
					<Tooltip
						id="margin-borrow"
						place="bottom"
						text={formatMessage(financeMessages.borrow_tooltip)}
					/>
					<Tooltip
						id="margin-repay"
						place="bottom"
						text={formatMessage(financeMessages.repay_tooltip)}
					/>
				</>
			)}
			{isMarginRiskModalOpened && (
				<MarginRiskModal
					onClose={handleCloseMarginRiskModal}
					onCloseAccepted={handleCloseAcceptedMarginRiskModal}
					requiredVerificationLevel={marginRequiredVerificationLevel}
				/>
			)}
		</div>
	)
}

export default observer(WalletsTable)
